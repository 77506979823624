export const getFinance = (typeFinance,dateStart,dateEnd,patient,typeDate,typePayment,typeModel,nota,bankAccountId) => ({type: 'GET_FINANCE',typeFinance,dateStart,dateEnd,patient,typeDate,typePayment,typeModel,nota,bankAccountId});
export const getFinanceParcelas = (value,parcelas) => ({type: 'GET_FINANCE_PARCELAS',value,parcelas});
export const financeParcelasLoading = (status) => ({type: 'FINANCE_PARCELAS_LOADING',status});
export const financeParcelasSuccess = (data) => ({type: 'FINANCE_PARCELAS_SUCCESS',data});


export const getTypePayment = (typeFinance) => ({type: 'GET_TYPE_PAYMENT',typeFinance});
export const typePaymentSuccess = (data) => ({type: 'FINANCE_TYPE_PAYMENT',data});
export const typePaymentPaySuccess = (data) => ({type: 'FINANCE_TYPE_PAYMENT_PAY',data});
export const financeLoading = (status) => ({type: 'FINANCE_LOADING',status});

export const financeReceiveSuccess = (data) => ({type: 'FINANCE_RECEIVE_SUCCESS',data});
export const financeReceiveSet = (data) => ({type: 'FINANCE_RECEIVE_SET',data});
export const financeReceiveAlter = (data,id) => ({type: 'FINANCE_RECEIVE_ALTER',data,id});
export const financeReceiveDestroy = (id) => ({type: 'FINANCE_RECEIVE_DESTROY',id});
export const financeReceiveDestroyMult = (ids) => ({type: 'FINANCE_RECEIVE_DESTROY_MULT',ids});

export const financePaySuccess = (data) => ({type: 'FINANCE_PAY_SUCCESS',data});
export const financePaySet = (data) => ({type: 'FINANCE_PAY_SET',data});
export const financePayAlter = (data,id) => ({type: 'FINANCE_PAY_ALTER',data,id});
export const financePayDestroy = (id) => ({type: 'FINANCE_PAY_DESTROY',id});
export const financePayDestroyMult = (ids) => ({type: 'FINANCE_PAY_DESTROY_MULT',ids});

export const financeLoadingAction = (status) => ({type: 'FINANCE_LOADING_ACTION',status});
export const storeFinance = (data) => ({type: 'STORE_FINANCE',data});
export const updateFinance = (data,id) => ({type: 'UPDATE_FINANCE',data,id});
export const destroyFinance = (data) => ({type: 'DESTROY_FINANCE',data});

export const destroyFinanceTask = (data) => ({type: 'DESTROY_FINANCE_TASK',data});

export const financeReportPayment = (id,data,typeFinance) => ({type: 'REPORTPAYMENT_FINANCE',id,data,typeFinance});
export const financePayAddMult = (data) => ({type: 'FINANCE_PAY_ADD_MULT',data});
export const financeReceiveAddMult = (data) => ({type: 'FINANCE_RECEIVE_ADD_MULT',data});
