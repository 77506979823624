export const companyServiceSuccess = (data) => ({type: 'COMPANY_SERVICE_SUCCESS',data});
export const getCompanyService = () => ({type: 'GET_COMPANY_SERVICE'});
export const setLoading = (status) => ({type: 'SET_LOADING',status});
export const storeCompanyService = (data) => ({type: 'STORE_COMPANY_SERVICE',data});
export const updateCompanyService = (data,id) => ({type: 'UPDATE_COMPANY_SERVICE',data,id});
export const destroyCompanyService = (id) => ({type: 'DESTROY_COMPANY_SERVICE',id});













