import { put, takeEvery, select} from 'redux-saga/effects';
import api from '../../api';
import toast from "react-hot-toast";
import {closeModal} from "../actions/uiActions";
import {bankAccountLoading, bankAccountSuccess, bankSuccess} from "../actions/companyBankAccountActions";

export const getAuthToken = (state) => state.auth.token;
export const getPatient = (state) => state.patients.item;

function* get(){
    try{
        yield put(bankAccountLoading(true))
        const response = yield  api.get('/companyBankAccounts',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(bankAccountSuccess(response.data));
        }
    } catch (e) {
        console.error(e);
    }finally {
        yield put(bankAccountLoading(false));
    }
}


function* getBanks(){
    try{
        const response = yield  api.get('/companyBankAccounts/banks',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(bankSuccess(response.data));
        }
    } catch (e) {
        console.error(e);
    }
}



function* update(action){

    let response = api.put('/companyBankAccounts/'+action.id, action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Atualizando a conta bancária...',
            success: (success) => {
                return 'Conta bancária atualizada com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível atualizar a conta bancária.'
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield get({patient:action.data.user});
    }
}

function* store(action){
    let patient = yield select(getPatient);

    let response = api.post('/companyBankAccounts', action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Cadastrando conta bancária...',
            success: (success) => {
                return 'Conta bancária cadastrada com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível cadastrar a conta bancária.'
            }
        }
    );

    response = yield response;
    if (response.status === 201) {
        yield put(closeModal());
        yield get({patient:patient.id});
    }
}


function* destroy(action){
    let patient = yield select(getPatient);

    let response = api.delete('/companyBankAccounts/'+action.id, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Deletando a conta bancária...',
            success: (success) => {
                return 'Conta bancária deletado com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível deletar a conta bancária.'
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield put(closeModal());
        yield get({patient:patient.id});
    }
}

export function* companyBankAccountsSaga() {
    yield takeEvery('GET_BANK_ACCOUNT', get);
    yield takeEvery('GET_BANK', getBanks);
    yield takeEvery('UPDATE_BANK_ACCOUNT', update);
    yield takeEvery('STORE_BANK_ACCOUNT', store);
    yield takeEvery('DESTROY_BANK_ACCOUNT', destroy);
}