import React from "react";
import xml from "../../assets/img/xml.png";
import pdf from "../../assets/img/pdf.png";
import {formatDate} from "../../utils";
import {DateTime} from "luxon";
function InvoiceEventModal({data}) {
    const invoices = [...data.data];

    invoices.sort((a, b) =>
        DateTime.fromISO(b.created_at).toMillis() - DateTime.fromISO(a.created_at).toMillis()
    );


    return (
        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
                <tr className="fw-bolder text-muted">
                    <td style={{textAlign:"left"}}>Data</td>
                    <td style={{textAlign:"left"}}>Evento</td>
                    <td style={{textAlign:"left"}}></td>
                    <td style={{textAlign:"left"}}></td>
                </tr>
            </thead>
            <tbody>
            {
                invoices?.map((value) => {
                    return(
                        <tr>
                            <td style={{textAlign:"left"}}>{formatDate(DateTime.fromISO(value.created_at))}</td>
                            <td style={{textAlign:"left"}}><p style={{width:200,whiteSpace:"normal"}}>{value.message}</p></td>
                            <td style={{textAlign:"left"}}>{value.url_pdf ? <a href={value.url_pdf} target="_new"><img src={pdf} style={{maxWidth: 30}} alt={""}/></a> : <></>}</td>
                            <td style={{textAlign:"left"}}>{value.url_xml ? <a href={value.url_xml} target="_new"><img src={xml} style={{maxWidth: 30}} alt={""}/></a> : <></>}</td>
                        </tr>
                    )
                })
            }

            </tbody>
        </table>
    );
}

export default InvoiceEventModal