import React from "react";
import {calcularMoney, checkPermission, formatDateNotHour, getName} from "../../utils";
import {DateTime} from "luxon";
import ContentLoader from "react-content-loader";
import {Link} from "react-router-dom";
import Button from "./Button";
import {Avatar} from "../Avatar";
import InvoiceBadge from "./InvoiceBadge";



const FinanceMobileList = ({value, auth, openModal, destroy, permission, destroyFinanceTask, typeModel, setValue, setConfirmOne, setConfirmTask}) => {


    return(
        <div className="d-flex align-items-center mb-6 table-row-dashed" style={{borderBottomColor:"#f1f1f1", borderBottomWidth:1,borderBottomStyle:"solid",paddingBottom:10}}>
            <div className="flex-grow-1">
                <div className="text-black-900 fs-5 fw-bold text-bold " style={{display:"flex",fontSize:11}}>
                    <div style={{flex:1}}>
                        {
                            typeModel === 1 ?
                                <>
                                    {
                                        value.user && value.user.type !== 1  ?
                                            <Link
                                                style={{textAlign: 'left',display:"flex",gap:5,alignItems: "center"}}
                                                to={"/patient/" + value.user?.id}
                                                className="btn btn-link"
                                            >
                                                <Avatar w="w-25px" h="h-25px" row={value.user}  /> <div>{value.user ? getName(value.user.name) : ''}</div>
                                            </Link>
                                            :
                                            <>{value.user ? value.user.name : ''}</>
                                    }
                                </>
                                :
                                <div style={{marginTop:8}}>
                                    {
                                        value.company_user?.email && (value.company_user?.cpf || value.company_user?.cnpj) &&
                                        <span
                                            className="svg-icon svg-icon-success svg-icon-1hx"
                                            style={{marginRight: 10}} title={"Cadastro completo"}
                                        >
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.3"
                                                d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                fill="currentColor"
                                            />
                                            <path
                                                d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    </span>
                                    }
                                    {permission === 'pay' ? value.user ? value.user.name : '' : value.company_user ? value.company_user.description : ''}
                                </div>
                        }
                    </div>
                    <div style={{width:35}}>
                    <Button
                        setValue={setValue}
                        setConfirmOne={setConfirmOne}
                        setConfirmTask={setConfirmTask}
                        auth={auth}
                        permission={permission}
                        typeModel={typeModel}
                        value={value}
                        destroy={destroy}
                        openModal={openModal}
                        destroyFinanceTask={destroyFinanceTask}
                    />
                    </div>
                </div>
                <div className="text-gray-400 fw-semibold fs-7">
                    Data de vencimento:  {formatDateNotHour(DateTime.fromSQL(value.due_date))}
                </div>
                <div className="text-gray-400 fw-semibold fs-6"style={{maxWidth:300,textAlign:"left",marginTop:10, marginBottom:10}}>
                    {value.description}
                </div>
                {
                    value.type_payment ?
                        <div className="text-gray-700 fw-semibold fs-6"style={{maxWidth:300,textAlign:"justify",marginTop:10, marginBottom:5}}>
                            <div className="badge badge-light-primary"  style={{height:22}}>
                                {value.type_payment.description}
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    value.company_bank_account ?
                        <div className="text-gray-700 fw-semibold fs-6"style={{maxWidth:300,textAlign:"justify",marginTop:5, marginBottom:5}}>
                            <div style={{display:"flex",flexDirection:"column",gap:5,alignItems: "flex-start"}}>
                                <div className="badge badge-primary"  style={{height:22}} title={"Agência: "+value.company_bank_account?.agency+ ' Conta: '+value.company_bank_account?.account}>
                                    {value.company_bank_account?.bank?.bank}
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }

                <InvoiceBadge value={value} />

                {
                    value.responsible?.id ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{maxWidth:300,textAlign:"justify",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-light-dark">
                                Recebeu de {getName(value.responsible?.responsible.name)}
                            </div>
                        </div>
                        :
                        <></>
                }

                <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:10}}>
                    <div className="badge badge-light-dark" style={{textAlign:"left",background:value.status.color,color:'#fff'}}>
                        {value.status.description}
                        {value.payment_date ? <span> em {formatDateNotHour(DateTime.fromSQL(value.payment_date))}</span> : <></> }
                    </div>
                </div>

                <div className="text-gray-700 fw-semibold fs-3">
                    {
                        calcularMoney(value.total).
                        toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                        })
                    }
                </div>
                <br/>
                {
                    value.nota === 1 ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:10, marginBottom:5}}>
                            <div className="badge badge-info" >
                                Nota solicitada
                            </div>
                        </div>
                        :

                        <></>
                }

                {
                    value.nota === 2 ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-info" >
                                Nota anexada
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    value.nota === 3 ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-success" >
                                Nota faturada
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    value && value.documents && value.documents.id ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-primary" >
                                Documentos anexados
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    value?.task > 0 ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-light-dark" >
                                Receita recorrente
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    value?.group > 0 ?
                        <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                            <div className="badge badge-light-dark" >
                                Receita parcelada
                            </div>
                        </div>
                        :
                        <></>
                }

                { typeModel === 1 && value.user?.status === 0 &&
                    <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:5}}>
                        <div className="badge badge-danger" >
                            Paciente inativo
                        </div>
                    </div>
                }

                { typeModel === 2 && value.company_user?.status === 0 &&
                    <div className="text-gray-700 fw-semibold fs-6" style={{textAlign:"left",marginTop:5, marginBottom:10}}>
                        <div className="badge badge-danger" >
                            Clínica desativada
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}


const FinanceMobile = ({
                           handlerPagos,
                           handlerVencidos,
                           handlerTodos,
                           handlerRecebidos,
                           handlerVencem,
                           handlerAvencer,
                           valueTotal,
                           valueAvencer,
                           valueVencem,
                           valueRecebidos,
                           valueVencidos,
                           valuePagos,
                           title,
                           patient,
                           permission,
                           typeModel,
                           auth,
                           loading,
                           openModal,
                           name,
                           destroyFinanceTask,
                           report,
                           setConfirmOne,
                           setConfirmTask,
                           setValue,
                           search,
                           handleSearch,
                           searchTerm,
                           dateStart,
                           dateEnd,
                           destroy,
                           filter,
                            openAny
                       }) => {
    return(
        <>
            <div className="card" style={{borderRadius: 0,marginBottom:10}}>
                <div className="card-body" style={{textAlign: 'center', paddingTop: 0}}>
                    <div style={{display: 'flex', flexDirection: 'row',paddingTop:10, marginBottom: 30}}>
                        <div
                            style={{
                                flex: 1,
                                textAlign: 'left'
                            }}
                        >
                            {formatDateNotHour(DateTime.fromSQL(dateStart))} - {formatDateNotHour(DateTime.fromSQL(dateEnd))}
                        </div>
                        <div>
                            <div
                                onClick={() => openAny(filter, 'Filtro', 'lg'
                                )}
                            >
                                    <span className="svg-icon svg-icon-black ">
                                        <svg width="20" height="24" viewBox="0 0 20 20" fill="#000"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <rect x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                                  transform="rotate(45 17.0365 15.1223)" fill="#000"/>
                                            <path
                                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                fill="#000"/>
                                        </svg>
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        {
                            permission === "pay" ?
                                checkPermission(auth.permissions, 'paid.pay.list') &&
                                <h1 onClick={() => handlerPagos()} style={{flex: 1, color: '#26b547'}}>
                                    {
                                        loading === true ?
                                            <ContentLoader viewBox="0 0 380 70">
                                                <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                            </ContentLoader>
                                            :
                                            calcularMoney(valuePagos).toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            })
                                    }
                                    <br/><small style={{opacity: 0.4}}>Pagos</small>
                                </h1>
                                :
                                checkPermission(auth.permissions, 'received.receive.list') &&
                                <h1 onClick={() => handlerRecebidos()} style={{flex: 1, color: '#26b547'}}>
                                    {
                                        loading === true ?
                                            <ContentLoader viewBox="0 0 380 70">
                                                <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                            </ContentLoader>
                                            :
                                            calcularMoney(valueRecebidos).toLocaleString('pt-br', {
                                                style: 'currency',
                                                currency: 'BRL'
                                            })
                                    }

                                    <br/><small style={{opacity: 0.4}}>Recebidos</small>
                                </h1>
                        }
                        {checkPermission(auth.permissions, permission === "pay" ? 'total.pay.list' : 'total.receive.list') &&
                            <h1 onClick={() => handlerTodos()} style={{flex: 1, color: '#000'}}>
                                {
                                    loading === true ?
                                        <ContentLoader viewBox="0 0 380 70">
                                            <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                        </ContentLoader>
                                        :
                                        calcularMoney(valueTotal).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })
                                }
                                <br/><small style={{opacity: 0.4}}>Total</small></h1>}
                    </div>
                    <hr style={{background:'#f2f2f2'}} />
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: 20}}>
                        {checkPermission(auth.permissions, 'overdue.' + permission + '.list') &&
                            <h1 onClick={() => handlerVencidos()} style={{flex: 1, color: '#ff3d32', fontSize: 14}}>
                                {
                                    loading === true ?
                                        <ContentLoader viewBox="0 0 380 70">
                                            <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                        </ContentLoader>
                                        :
                                        calcularMoney(valueVencidos).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })
                                }
                                <br/><small style={{opacity: 0.4}}>Vencidos</small>
                            </h1>}
                        {checkPermission(auth.permissions, 'expiretoday.' + permission + '.list') &&
                            <h1 onClick={() => handlerVencem()} style={{flex: 1, color: '#ffc700', fontSize: 14}}>
                                {
                                    loading === true ?
                                        <ContentLoader viewBox="0 0 380 70">
                                            <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                        </ContentLoader>
                                        :
                                        calcularMoney(valueVencem).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })
                                }
                                <br/><small style={{opacity: 0.4}}>Vencem hoje</small>
                            </h1>}
                        {checkPermission(auth.permissions, 'towin.' + permission + '.list') &&
                            <h1 onClick={() => handlerAvencer()} style={{flex: 1, color: '#2687e9', fontSize: 14}}>
                                {
                                    loading === true ?
                                        <ContentLoader viewBox="0 0 380 70">
                                            <rect x="115" y="17" rx="4" ry="4" width="150" height="30"/>
                                        </ContentLoader>
                                        :
                                        calcularMoney(valueAvencer).toLocaleString('pt-br', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })
                                }
                                <br/><small style={{opacity: 0.4}}>A vencer</small>
                            </h1>}
                    </div>
                </div>
            </div>
            <div className="row" style={{paddingLeft:20,paddingRight:20,marginBottom:10}}>
                <div className="col-md-3">

                    <input
                        className="form-control"
                        value={searchTerm}
                        onChange={handleSearch}
                        style={{height: 38}}
                        placeholder="Pesquisar"
                    />

                </div>
                <div className="col-md-6">

                </div>
                <div className="col-md-3">

                </div>
            </div>
            <div style={{padding:20,background:'#fff'}}>
                {
                    search.map((value, index) => {
                        return (
                            <FinanceMobileList
                                key={index}
                                value={value}
                                auth={auth}
                                permission={permission}
                                typeModel={typeModel}
                                openModal={openModal}
                                destroyFinanceTask={destroyFinanceTask}
                                report={report}
                                setValue={setValue}
                                setConfirmOne={setConfirmOne}
                                setConfirmTask={setConfirmTask}
                            />
                        )
                    })
                }
            </div>

        </>
    )
}

export default FinanceMobile;


