import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../Field";
import {Button} from "../Button";
import Api from "../../api";
import {useSelector} from "react-redux";

function ServiceCompanyModal({data,storeCompanyService,updateCompanyService}) {
    const [services,setServices] = useState([])
    const auth = useSelector( state => state.auth);



    const getServices = async () => {
        try{
            const response = await Api.get('/companyService/services',{
                headers: {
                    'Authorization': 'Bearer ' + auth.token,
                }
            });

            setServices(response.data);
        }catch (e){

        }
    }

    const options = services?.map(value => ({value:value.id,label:"("+value.service.code+") "+value.service.description}))

    useEffect(() => {
        getServices();
    }, []);

    const onSubmit = request => {
        if(data.id){
            updateCompanyService(request,data.id)
        }else{
            storeCompanyService(request)
        }
    }

    const { control, handleSubmit,  formState: { errors } } = useForm({
        defaultValues:{...data}
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Serviço*:</label>
                    <Controller
                        name="service_id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange,  value }}) => <Field options={options}   type="select" value={value} onChange={onChange} />}
                    />
                    {errors.service_id && <p style={{color:'red',marginTop:10}}>Necessário selecionar o serviço.</p>}
                </div>

                <div className={"col-lg-3"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Alíquota ISS:</label>
                    <Controller
                        name="iss"
                        control={control}
                        render={({ field : { onChange,  value }}) =>
                            <Field
                                type="number"
                                value={value}
                                onChange={onChange}
                            />
                        }
                    />
                </div>
                <div className={"col-lg-12"} style={{marginTop: 10}} >
                    <label className="fw-bold text-muted">Descrição do serviço:</label>
                    <Controller
                        name="description"
                        control={control}
                        render={({ field : { onChange,  value }}) =>
                            <Field
                                type="textArea"
                                value={value}
                                onChange={onChange}
                            />
                        }
                    />
                </div>

            </div>
            <div className="row" style={{textAlign: 'right', marginTop: 10}}>
                <div className="col-md-9">

                </div>
                <div className="col-md-3">
                    <Button text="Salvar" type="submit"/>
                </div>
            </div>
        </form>
    );
}

export default ServiceCompanyModal;
