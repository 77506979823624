import React from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../Field";
import {Button} from "../Button";
import toast from "react-hot-toast";
import api from "../../api";
import {useSelector} from "react-redux";

function InvoiceCancelModal({id,closeModal}) {
    const { control, handleSubmit,  formState: { errors } } = useForm({
        defaultValues:{

        }
    });

    const token = useSelector(state => state.auth.token)

    const onSubmit = request => {
        let response = api.post('/invoice/cancel/'+id, {reason:request.reason}, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });


        toast.promise(response,
            {
                loading: 'Cancelando a nota...',
                success: (data) => {
                    closeModal()
                    return data.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        );
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className={"col-lg-12"} style={{marginTop:15}}>
                    <label className="fw-bold text-muted">Motivo:</label>
                    <Controller
                        name="reason"
                        control={control}
                        rules={{ required: true }}
                        render={({ field : { onChange, onBlur, value }}) => <Field type="textArea" value={value} onChange={onChange} />}
                    />
                    {errors.reason && <p style={{color:'red',marginTop:10}}>Necessário preencher o motivo.</p>}
                </div>
            </div>
            <div className="row" style={{textAlign:'right',marginTop:20}}>
                <div className="col-md-9"></div>
                <div className="col-md-3">
                    <Button text="Salvar" type="submit" />
                </div>
            </div>
        </form>
    );
}

export default InvoiceCancelModal