import React from "react";
import {useForm} from "react-hook-form";
import {Button} from "../Button";
import toast from "react-hot-toast";
import api from "../../api";
import {useSelector} from "react-redux";

function InvoiceStoreModal({data,closeModal}) {
    const {handleSubmit } = useForm({
        defaultValues:{

        }
    });

    const token = useSelector(state => state.auth.token)

    const onSubmit = request => {
        let response = api.post('/invoice', {id:data.id}, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });


        toast.promise(response,
            {
                loading: 'Criando a nota fiscal...',
                success: (data) => {
                    closeModal()
                    return data.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        );
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row" style={{textAlign:'left',marginTop:20}}>
                <div className="col-md-2">
                    <Button text="Sim" type="submit" />
                </div>
                <div className="col-md-3">
                    <button onClick={() => closeModal() } type="button" className="btn btn-sm btn-danger" >Não</button>
                </div>
            </div>
        </form>
    );
}

export default InvoiceStoreModal