import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './authReducer';
import companiesReducer from './companiesReducer';
import calendarReducer from './calendarReducer';
import patientsReducer from './patientsReducer';
import uiReducer from './uiReducer';
import procedureReducer from './procedureReducer';
import groupsReducer from './groupsReducer';
import reportsReducer from './reportsReducer';
import documentsReducer from './documentsReducer';
import planReducer from './planReducer';
import professionReducer from './professionReducer';
import professionalsReducer from './professionalsReducer';
import orientationsReducer from './orientationsReducer';
import expedientsReducer from './expedientsReducer';
import profilesReducer from './profilesReducer';
import timeBlockReducer from './timeBlockReducer';
import financeReducer from './financeReducer';
import accountReducer from './accountReducer';
import treatmentReducer from './treatmentReducer';
import serviceReducer from './serviceReducer';
import diagnosticReducer from "./diagnosticReducer";
import operatorsReducer from "./operatorsReducer";
import healthInsuranceReducer from "./healthInsuranceReducer";
import userProfessionReducer from "./userProfessionReducer";
import queueWaitReducer from "./queueWaitReducer";
import companyBankAccountReducer from "./companyBankAccountReducer";
import companyServiceReducer from "./companyServiceReducer";

const rootReducer = (history) => combineReducers({
  patients:patientsReducer,
  calendar:calendarReducer,
  auth: authReducer,
  companies: companiesReducer,
  ui:uiReducer,
  groups:groupsReducer,
  router: connectRouter(history),
  procedures:procedureReducer,
  reports:reportsReducer,
  documents:documentsReducer,
  plan:planReducer,
  profession:professionReducer,
  professionals:professionalsReducer,
  orientations:orientationsReducer,
  expedients:expedientsReducer,
  profiles:profilesReducer,
  timeBlock:timeBlockReducer,
  finance:financeReducer,
  account:accountReducer,
  treatment:treatmentReducer,
  service:serviceReducer,
  diagnostic:diagnosticReducer,
  operators:operatorsReducer,
  healthInsurance:healthInsuranceReducer,
  userProfession:userProfessionReducer,
  queueWait:queueWaitReducer,
  companyBankAccount:companyBankAccountReducer,
  companyService:companyServiceReducer
})

export default rootReducer;