import React, {useEffect, useState} from "react";

import Api from "../../../api";
import ContentLoader from "react-content-loader";
import {checkPermission} from "../../../utils";
import {openModal} from "../../../store/actions/uiActions";
import {getOperator, updateOperator} from "../../../store/actions/operatorsActions";
import {connect} from "react-redux";
import {destroyCompanyService, getCompanyService} from "../../../store/actions/companyServiceActions";


function ServiceList({value,openModal,updateOperator,auth,destroyCompanyService}){
    const [novo,setNovo] = useState(false);

    useEffect(() => {
        function click(ev) {
            if(ev.srcElement.closest('#service') == null){
                setNovo(false);
            }
        }

        if(novo){
            window.addEventListener('click',click,false);
            return () => {
                window.removeEventListener('click',click,false);
            };
        }

    }, [novo]);

    return(
        <tr>
            <td style={{textAlign:'left'}}>{"("+value.service?.service?.code+") "+value.service?.service?.description}</td>
            <td style={{textAlign:'left'}}>{value.iss}</td>
            <td style={{textAlign:'left'}}>{value.description}</td>
            <td>
                <button  className="btn btn-sm btn-icon  btn-active-color-primary" style={{float:'right'}} onClick={()=>setNovo(true)}>
                    <span className="svg-icon svg-icon-muted svg-icon-2hx">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"/>
                            <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                            <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                        </svg>
                    </span>
                </button>
                <div className={"dropdown-menu "+(novo ? "show" : '')} style={{marginTop:40,right:33}} id="service" >
                    {checkPermission(auth.permissions,'operators.update') ? <a className="dropdown-item" style={{cursor:'pointer'}} onClick={()=>{ openModal('serviceCompanyModal', value); setNovo(false) }}>Editar</a> : <></>}
                    {checkPermission(auth.permissions,'operators.status') ? <a className="dropdown-item" style={{cursor:'pointer',color:'red'}} onClick={()=>{  destroyCompanyService(value.id); setNovo(false) }}>Excluir</a>: <></>}
                </div>
            </td>
        </tr>
    )
}
function Services({items,auth,openModal,getCompanyService,loading,destroyCompanyService}){

    useEffect(() => {
        getCompanyService()
    },[]);


    return (
        <div className="card" style={{marginTop:20}}>
            <div className="card-header">
                <div className="card-title align-items-start flex-column">
                    <span className="fw-bolder m-0">Lista de serviços <small>Lei Complementar n° 116/03</small></span>
                </div>
                <div className="card-toolbar">
                    {checkPermission(auth.permissions,'operators.store') ?  <button className="btn btn-sm btn-light-info" onClick={() => openModal('serviceCompanyModal',{}) }>Adicionar</button> : <></>}
                </div>
            </div>
            <div className="card-body">
                <div className='table-responsive'>
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">

                            <th style={{textAlign:'left'}}>Código de serviço municipal</th>
                            <th style={{textAlign:'left'}}>ISS</th>
                            <th style={{textAlign:'left'}}>Descrição do serviço</th>
                            <th></th>
                        </tr>
                        </thead>
                        {
                            loading === true ?
                                <tbody>
                                {
                                    [1,2,3].map((value,index) =>{
                                        return(
                                            <tr key={index}>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 200 10">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 200 10">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                                                    </ContentLoader>
                                                </td>
                                                <td style={{textAlign: 'left'}}>
                                                    <ContentLoader viewBox="0 0 200 10">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                                                    </ContentLoader>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                                :
                                <tbody>
                                {
                                    items && items.length > 0 ?
                                        items.map((value,index) =>{
                                            return <ServiceList destroyCompanyService={destroyCompanyService} auth={auth} value={value} key={index} openModal={openModal} />
                                        })
                                        :
                                        <tr>
                                            <td colSpan="6"><div style={{textAlign:"center",paddingTop:10}}><h6>Não foi encontrado nenhum serviço.</h6><small>Cadastre aqui os seus serviços que serão utilizados na emissão das suas notas.
                                                <br/> É possível cadastrar quantos serviços você desejar.</small></div></td>
                                        </tr>
                                }
                                </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth,
    items: state.companyService.items,
    loading: state.companyService.loading
})

const mapDispatchToProps = dispatch => ({
    openModal:(modal,data) => dispatch(openModal(modal,data)),
    getOperator:() => dispatch(getOperator()),
    updateOperator:(data,id) => dispatch(updateOperator(data,id)),
    getCompanyService:() => dispatch(getCompanyService()),
    destroyCompanyService:(id) => dispatch(destroyCompanyService(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(Services)