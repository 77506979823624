import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import api from "../../api";
import ContentLoader from "react-content-loader";

function AuditModal({data}) {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false)

    const token = useSelector(state => state.auth.token);

    useEffect(()=>{
        async function getAudit(){
            setLoading(true)
            const response = await api.post('/audit',{
                auditable_id:data.auditableId,
                auditable_type:data.auditableType,
                model:data.model,
            },{
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });

            if(response.status === 200) setLogs(response.data);
            setLoading(false)
        }

        getAudit();
    },[]);

    const dictionary ={
        created: "Criado",
        updated: "Atualizado",
        type_payment: "Forma de pagamento ou Tipo de conta",
        payment_date: "Data de pagamento ou recebimento",
        updated_at: "Data de atualização",
        status: "Situação",
        description: "Descrição",
        due_date: "Data de vencimento",
        receipt_account: "Data de recebimento ou pagamento",
        total: "Valor",
        user: "Paciente ou profissional",
        bank_account_id: "Conta Bancária",
        responsible_id : "Responsável"
    }

    const ignore = {
        'finance':{
            'type':true,
            'company':true,
            'type_model':true,
            'created_at':true,
            'id':true,
            'task':true,
            'updated_at':true,
        }
    }


    const alter = (text) =>{

        if(!text){
            return 'N/A'
        }

        return text;
    }



    return (
        <div>
            {
                loading ?
                    <>
                        <ContentLoader viewBox="0 0 380 15">
                            <rect x="0" y="0" rx="4" ry="4" width="150" height="10"/>
                        </ContentLoader>
                        <ContentLoader viewBox="0 0 380 15">
                            <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                        </ContentLoader>
                        <ContentLoader viewBox="0 0 380 15">
                            <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                        </ContentLoader>

                        <hr style={{background: '#c1c1c1', marginTop: 30,marginBottom:30}}/>
                        <ContentLoader viewBox="0 0 380 15">
                            <rect x="0" y="0" rx="4" ry="4" width="150" height="10"/>
                        </ContentLoader>
                        <ContentLoader viewBox="0 0 380 15">
                            <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                        </ContentLoader>
                        <ContentLoader viewBox="0 0 380 15">
                            <rect x="0" y="0" rx="4" ry="4" width="100" height="10"/>
                        </ContentLoader>

                    </>
                    :
                    logs?.map((value,index) => {
                    console.log(value)
                    return(
                        <div key={index} style={{marginBottom:30}}>
                            <h4>{value.user_id == 'suporte' ? "Sistema" : value.user_id == 'suporte' ? "Sistema" :  value.user_id  }</h4>
                            <p><b>Evento:</b> {dictionary[value.event]}</p>
                            <p><b>Quando:</b> {value.created_at}</p>

                            <div className="row">
                                {
                                    value.event !== 'created' && <div className="col-md-6">
                                        <div className="card" style={{marginBottom:20}}>
                                            <div className="card-header" style={{minHeight:40}}>
                                                <div className="card-title"><h2 style={{paddingLeft:0}}>De</h2></div>
                                            </div>
                                            <div className="card-body">
                                                {
                                                    Object.keys(value.old_values).length > 0 ?
                                                         Object.keys(value.old_values)?.map((key,index) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        !ignore[data.model][key] && <p>
                                                                            <b style={{marginRight:10}}>{dictionary[key] ?? key}:</b><br/>
                                                                            {alter(value.old_values[key])}
                                                                        </p>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    :
                                                        <div>Sem dados </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-md-6">

                                    <div className="card">
                                        <div className="card-header" style={{minHeight:40}}>
                                            <div className="card-title"><h2 style={{paddingLeft:0}}>{value.event === 'created' ? "Dados do cadastro" : "Para"}</h2></div>
                                        </div>
                                        <div className="card-body">
                                            {
                                                Object.keys(value.new_values).length > 0 ?
                                                    Object.keys(value.new_values)?.map((key,index) => {
                                                        return (
                                                            <>
                                                            {
                                                                !ignore[data.model][key] && <p>
                                                                    <b style={{marginRight: 10}}>{dictionary[key] ?? key}:</b><br/> {alter(value.new_values[key])}
                                                                </p>
                                                            }
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    <div>Sem dados </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr style={{background:'#c1c1c1',marginTop:30}}/>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default AuditModal