import React from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../../components/Field";
import Api from "../../api";
import toast from "react-hot-toast";

export default function Company({data,company,token,auth}){
    const {control, handleSubmit, formState: { errors },setValue } = useForm({
        defaultValues:{
            ...data
        }
    });
    const getCNPJ = async (cnpj) => {
        const response = await Api.get("https://api-publica.speedio.com.br/buscarcnpj?cnpj="+cnpj);
        const company = response.data

        setValue('description',company['RAZAO SOCIAL'] ?? "")
        setValue('email',company['EMAIL'] ?? "")
        setValue('fantasy_name',company['NOME FANTASIA'] ?? "")
        setValue('cep',company['CEP'] ?? "")
        setValue('city',company['MUNICIPIO'] ?? "")
        setValue('state',company['UF'] ?? "")
        setValue('neighborhood',company['BAIRRO'] ?? "")
        setValue('street',company['LOGRADOURO'] ? company['TIPO LOGRADOURO']+" "+company['LOGRADOURO'] : "")
        setValue('neighborhood',company['BAIRRO'] ?? "")
        setValue('number',company['NUMERO'] ?? "" )
        setValue('complement',company['COMPLEMENTO'] ?? "");
        setValue('phone',company['TELEFONE'] ? company['DDD']+company['TELEFONE'] : "");
    }

    const crt = [
        {
            value:"simplesNacional",
            label:"Simples nacional"
        },
        {
            value:"simplesNacionalExcessoSublimite",
            label:"Simples nacional excesso de sublimite"
        },
        {
            value:"simplesNacionalMEI",
            label:"Simples nacional - MEI"
        },
        {
            value:"regimeNormal",
            label:"Regime Normal"
        }
    ]

    const regimeSpaceal = [
        {
            value:"municipalMicroenterprise",
            label:"Microempresa municipal"
        },
        {
            value:"estimate",
            label:"Estimativa"
        },
        {
            value:"societyOfProfessionals",
            label:"Sociedade de profissionais"
        },
        {
            value:"cooperative",
            label:"Cooperativa"
        },
        {
            value:"individualMicroenterprise",
            label:"Microempresário individual (MEI)"
        },
        {
            value:"microenterpriseAndSmallBusiness",
            label:"Microempresário e empresa de pequeno porte"
        },
        {
            value:"noSpecialRegime",
            label:"Sem regime especial"
        }
    ]

    const typeTaxation = [
        {
            label:'Tributação no município',
            value:'taxationInMunicipality'
        },
        {
            label:'Tributação fora município',
            value:'taxationOutsideMunicipality'
        },
        {
            label:'Isenção',
            value:'exemption'
        },
        {
            label:'Imune',
            value:'immune'
        },
        {
            label:'Tribunal suspenso',
            value:'suspendedByCourt'
        },
        {
            label:'Suspenso por procedimento administrativo',
            value:'suspendedByAdministrativeProcedure'
        },
        {
            label:'Exportação',
            value:'exportation'
        }
    ]

    const onSubmit = async(request) => {
        const response = Api.put(
            "/company/"+company,
            request,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            });

        toast.promise(response,
            {
                loading: 'Atualizado os dados da clínica...',
                success: (data) => {
                    return data.data
                },
                error: (error) => {
                    return error.response.data
                }
            }
        );
    }

    return (
        <div className="card">
            <div className="card-header">
                <h1  className="card-title">Configuração da clínica</h1>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        {
                            data?.type === 1 ?
                                <div className="col-md-2">
                                    <label className="fw-bold text-muted" style={{marginTop:10}}>CNPJ:</label>
                                    <Controller
                                        name="cnpj"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field : { onChange,  value }}) => <Field  type="cnpj" value={value}  onChange={onChange} onBlur={(ev) => getCNPJ(ev.replace(/[.\-\/]/g, '')) } />}
                                    />
                                    {errors.cnpj && <p style={{color:'red',marginTop:10}}>Necessário preencher a CNPJ.</p>}
                                </div>
                                :
                                    auth.company?.type === 2 ?
                                        <div className="col-md-2">
                                            <label className="fw-bold text-muted" style={{marginTop:10}}>CPF:</label>
                                            <Controller
                                                name="cpf"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field : { onChange,  value }}) => <Field  type="cpf" value={value}  onChange={onChange} onBlur={(ev) => getCNPJ(ev.replace(/[.\-\/]/g, '')) } />}
                                            />
                                            {errors.cnpj && <p style={{color:'red',marginTop:10}}>Necessário preencher o CPF.</p>}
                                        </div>
                                        :
                                        <></>
                        }
                        <div className="col-md-5">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>{data?.type === 1 ? "Razão social" : "Nome"}:</label>
                            <Controller
                                name="description"
                                control={control}
                                rules={{ required: true }}
                                render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                            />
                            {errors.description && <p style={{color:'red',marginTop:10}}>Necessário preencher a razão social .</p>}
                        </div>
                        {
                            data?.type === 1 ?
                                <>
                                    <div className="col-md-5">
                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Nome fantasia:</label>
                                        <Controller
                                            name="fantasy_name"
                                            control={control}
                                            render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Inscrição estadual:</label>
                                        <Controller
                                            name="state_registration"
                                            control={control}
                                            render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Inscrição municipal:</label>
                                        <Controller
                                            name="municipal_registration"
                                            control={control}
                                            render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="fw-bold text-muted" style={{marginTop:10}}>CRT:</label>
                                        <Controller
                                            name="regime"
                                            control={control}
                                            render={({ field : { onChange,  value }}) => <Field  options={crt} type="select" value={value}  onChange={onChange} />}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Regime Especial:</label>
                                        <Controller
                                            name="special_regime"
                                            control={control}
                                            render={({ field : { onChange,  value }}) => <Field  options={regimeSpaceal} type="select" value={value}  onChange={onChange} />}
                                        />
                                    </div>

                                    <div className="col-md-2">
                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Tipo de tributação:</label>
                                        <Controller
                                            name="type_taxation"
                                            control={control}
                                            render={({ field : { onChange,  value }}) => <Field options={typeTaxation} type="select" value={value}  onChange={onChange} />}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Código CNAE:</label>
                                        <Controller
                                            name="cnae_code"
                                            control={control}
                                            render={({ field : { onChange,  value }}) => <Field type="text" value={value}  onChange={onChange} />}
                                        />
                                    </div>
                                </>
                                :
                                <></>
                        }
                        <div className="col-md-3">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>Email:</label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{ required: true }}
                                render={({ field : { onChange,  value }}) => <Field  type="email" value={value}  onChange={onChange} />}
                            />
                            {errors.email && <p style={{color:'red',marginTop:10}}>Necessário preencher a email.</p>}
                        </div>
                        <div className="col-md-2">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>Telefone:</label>
                            <Controller
                                name="phone"
                                control={control}
                                render={({ field : { onChange,  value }}) => <Field  type="cellphone" value={value}  onChange={onChange} />}
                            />
                        </div>
                        <div className="col-lg-12">
                            <br/>
                            <br/>
                            <h6>Endereco</h6>
                            <hr/>
                        </div>
                        <div className="col-md-3">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>CEP:</label>
                            <Controller
                                name="cep"
                                control={control}
                                render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                            />
                        </div>
                        <div className="col-md-3">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>Estado:</label>
                            <Controller
                                name="state"
                                control={control}
                                render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                            />
                        </div>
                        <div className="col-md-3">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>Cidade:</label>
                            <Controller
                                name="city"
                                control={control}
                                render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                            />
                        </div>
                        <div className="col-md-3">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>Bairro:</label>
                            <Controller
                                name="neighborhood"
                                control={control}
                                rules={{ required: true }}
                                render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                            />
                        </div>
                        <div className="col-md-5">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>Rua:</label>
                            <Controller
                                name="street"
                                control={control}
                                render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                            />
                        </div>
                        <div className="col-md-2">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>Numero:</label>
                            <Controller
                                name="number"
                                control={control}
                                render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                            />
                        </div>
                        <div className="col-md-5">
                            <label className="fw-bold text-muted" style={{marginTop:10}}>Complemento:</label>
                            <Controller
                                name="complement"
                                control={control}
                                render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                            />
                        </div>
                        <div className="col-md-11">
                        </div>
                        <div className="col-md-1" style={{textAlign:"right",marginTop:20}}>
                            <button className="btn btn-primary btn-sm"> Salvar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}