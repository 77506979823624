import Header from "./Header";
import Navigation from "./Navigation";
import React, {useEffect, useRef, useState} from "react";
import logo1 from "../../assets/img/usemed.png";
import {connect} from "../../websocket";
import {useMediaQuery} from "react-responsive";
import LayoutMobile from "./mobile/LayoutMobile";


export default function Layout({isMobile, children, auth,dispatch,patient,professionalCalendar,calendarRef}) {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' })

	const [isOpen,setOpen] = useState(!isMobile);
	const [shadow,setShadow] = useState(true);
	const layoutRef = useRef();
	const [headerRight,setHeaderRight] = useState();
	const [headerNavigation,setHeaderNavigation] = useState([]);

	useEffect(() => {
		if(auth.token) connect('wss://ws-care.wayfly.com.br:3000');
	},[auth])

	if(auth && auth.company && auth.company.status === 0){
		return (
			<div className="d-flex flex-column flex-root">
				<div className="d-flex flex-column flex-column-fluid">
					<div className="d-flex flex-row-fluid flex-column flex-column-fluid text-center p-10 py-lg-20">
						<a style={{cursor:'pointer'}}  className="pt-lg-20 mb-12">
							<img alt="logo" src={logo1} className="h-70px" />
						</a>
						<h1 className="fw-bold fs-2qx text-gray-800 mb-7">Sua conta está desativada</h1>
						<div className="fw-semibold fs-3 text-muted mb-15">
							{
								auth.company.plan < 2 ?
									<>
										<p> Olá {auth.name} o período de acesso gratuito em nossa plataforma para {auth.company.description} expirou. <br/>Espero que nesse tempo você tenha tido a oportunidade de conhecer e avaliar nossa plataforma, <br/> que foi desenvolvida para te auxiliar diariamente nos seus processos de trabalho.</p>
										<p>Caso você tenha interesse em continuar utilizando nossa plataforma e crescer junto conosco, <br/> vamos adorar continuar te ajudando. <br/> Basta entrar em contato com o comercail.</p>
									</>
									:
									<>
										<p> Olá {auth.name} infelizmente o seu acesso ao nosso software foi interrompido, pois a licença do software expirou. Para continuar usando o programa, é necessário renovar a sua licença. Por favor, entre em contato conosco para obter mais informações sobre como renovar a sua licença e retomar o acesso ao software.</p>
									</>
							}
						</div>
						<div className="text-center">
							<a target="_black" href={"https://api.whatsapp.com/send?phone=5521975336733&text=Olá, tenho interesse em continuar."} className="btn btn-lg btn-primary fw-bold">Contato com o comercial</a>
							<a onClick={() => dispatch({type:'AUTH_LOGOUT'})} className="btn btn-lg btn-danger fw-bold" style={{marginLeft:10,cursor:'pointer'}}>Sair do sistema</a>
						</div>
					</div>
				</div>
			</div>
		)
	}

	let style = {}

	if(isMobile){
		style = {
			marginTop:55
		}

		//return <LayoutMobile children={children} auth={auth} layoutRef={layoutRef} setHeaderNavigation={setHeaderNavigation} setHeaderRight={setHeaderRight} calendarRef={calendarRef} setShadow={setShadow} isMobile={isMobile} />
	}

	return (
  		<div className="page d-flex flex-row flex-column-fluid">
    		<Navigation isOpen={isOpen} setOpen={setOpen} auth={auth} dispatch={dispatch} isMobile={isMobile} />
			<div className={(isMobile ? "" : "wrapper")+" d-flex flex-column flex-row-fluid"}>
      			<Header headerNavigation={headerNavigation} headerRight={headerRight} shadow={shadow} isOpen={isOpen} setOpen={setOpen} auth={auth} dispatch={dispatch} patient={patient} professionalCalendar={professionalCalendar} calendarRef={calendarRef} isMobile={isMobile} />
				<div className="d-flex flex-column flex-column-fluid" >
					<div ref={layoutRef} className="container-xxl" style={style}>
						{React.cloneElement(children, {setHeaderNavigation,setHeaderRight,calendarRef: calendarRef,layoutRef:layoutRef,setShadow:setShadow,isMobile:isMobile})}
					</div>
				</div>
				{
					!isMobile ?
						<div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
							<div className="container-fluid d-flex flex-column flex-md-row flex-stack">
								<div className="text-dark order-2 order-md-1">
									<span className="text-gray-400 fw-semibold me-1">Desenvolvido com <span style={{
										marginTop: -10,
										fontSize: 30,
										color: 'red',
										fontWeight: 'bold'
									}}>♡</span> por </span>
									<a rel="noopener noreferrer" href="https://wayfly.com.br" target="_blank"
									   className="text-muted text-hover-primary fw-semibold me-2 fs-6">Wayfly</a>
								</div>
								<ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
									<li className="menu-item">
										<a target="_black"
										   href="https://api.whatsapp.com/send?phone=5521990452839&text=Olá estou precisando de ajuda."
										   className="menu-link px-2">Suporte por Whatsapp</a>
									</li>
								</ul>
							</div>
						</div>
						: <></>
				}

			</div>
		</div>
	);
}
