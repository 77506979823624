import React, {useEffect, useState} from "react";
import Company from "./Company";
import Profile from "../profile";
import UserController from "../professionals/UserController";
import ServicesController from "../services/ServicesController";
import TreatmentController from "../treament/TreatmentController";
import OperatorsController from "../operators/OperatorsController";
import BankAccountsController from "../finance/BankAccountsController";
import {connect, useSelector} from "react-redux";
import Certificate from "./invoice/Certificate";
import {openAny} from "../../store/actions/uiActions";
import Services from "./invoice/Services";
import Api from "../../api";
import ContentLoader from "react-content-loader";
import {checkPermission} from "../../utils";

const FormSkeletonLoader = () => (
    <div className="row">
        <div className="col-md-2">
            <ContentLoader height={80} width={200} viewBox="0 0 200 80">
                <rect x="0" y="10" rx="4" ry="4" width="60" height="15" /> {/* Label */}
                <rect x="0" y="35" rx="5" ry="5" width="180" height="25" /> {/* Input */}
            </ContentLoader>
        </div>
        <div className="col-md-5">
            <ContentLoader height={80} width={400} viewBox="0 0 400 80">
                <rect x="0" y="10" rx="4" ry="4" width="100" height="15" /> {/* Label */}
                <rect x="0" y="35" rx="5" ry="5" width="380" height="25" /> {/* Input */}
            </ContentLoader>
        </div>
        <div className="col-md-5">
            <ContentLoader height={80} width={400} viewBox="0 0 400 80">
                <rect x="0" y="10" rx="4" ry="4" width="100" height="15" /> {/* Label */}
                <rect x="0" y="35" rx="5" ry="5" width="380" height="25" /> {/* Input */}
            </ContentLoader>
        </div>
        {/* Outros campos */}
        <div className="col-md-3">
            <ContentLoader height={80} width={300} viewBox="0 0 300 80">
                <rect x="0" y="10" rx="4" ry="4" width="80" height="15" /> {/* Label */}
                <rect x="0" y="35" rx="5" ry="5" width="280" height="25" /> {/* Input */}
            </ContentLoader>
        </div>
        <div className="col-md-3">
            <ContentLoader height={80} width={300} viewBox="0 0 300 80">
                <rect x="0" y="10" rx="4" ry="4" width="80" height="15" /> {/* Label */}
                <rect x="0" y="35" rx="5" ry="5" width="280" height="25" /> {/* Input */}
            </ContentLoader>
        </div>
        <div className="col-md-11">

        </div>
        <div className="col-md-1" style={{ textAlign: "right", marginTop: 20 }}>
            <ContentLoader height={40} width={80} viewBox="0 0 80 40">
                <rect x="0" y="0" rx="5" ry="5" width="80" height="40" /> {/* Botão */}
            </ContentLoader>
        </div>
    </div>
);

function Config({openAny}){
    const [tab,setTab] = useState('config');
    const auth = useSelector(state => state.auth);
    const [loading,setLoading] = useState(false);
    const [data,setData] = useState({})

    const getCompany = async () => {
        setLoading(true)
        try{
            const response = await Api.get(
                "/company/"+auth.company.id,{
                    headers: {
                        'Authorization': 'Bearer ' + auth.token,
                    }
                });
            setData(response.data)
        }catch (e){

        }finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getCompany().then()
    },[])

    return (
        <>
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-bolder" style={{fontSize:12}}>

                <li className="nav-item mt-2" >
                    <a style={{cursor:'pointer'}}  className={"nav-link text-active-primary ms-0 me-10 py-5  position-relative "+(tab === 'config' ? "active" : "")} onClick={() => setTab('config')}>
                        Configuração
                    </a>
                    {
                        data?.type === 1 ?
                            <a style={{cursor:'pointer'}}  className={"nav-link text-active-primary ms-0 me-10 py-5  position-relative "+(tab === 'cert' ? "active" : "")} onClick={() => setTab('cert')}>
                                Nota Fiscal
                            </a>
                            :
                            <></>
                    }
                    {
                        checkPermission(auth.permissions,  'roles.list') && <a style={{cursor:'pointer'}}  className={"nav-link text-active-primary ms-0 me-10 py-5  position-relative "+(tab === 'profile' ? "active" : "")} onClick={() => setTab('profile')}>
                            Perfis
                        </a>
                    }
                    {
                        checkPermission(auth.permissions,  'professionals.list') && <a style={{cursor:'pointer'}}  className={"nav-link text-active-primary ms-0 me-10 py-5  position-relative "+(tab === 'professionals' ? "active" : "")} onClick={() => setTab('professionals')}>
                            Profissionais
                        </a>
                    }
                    {
                        checkPermission(auth.permissions,  'treatment.list') && <a style={{cursor:'pointer'}}  className={"nav-link text-active-primary ms-0 me-10 py-5  position-relative "+(tab === 'treatment' ? "active" : "")} onClick={() => setTab('treatment')}>
                            Tratamentos
                        </a>
                    }
                    {
                        checkPermission(auth.permissions,  'service.list') && <a style={{cursor:'pointer'}}  className={"nav-link text-active-primary ms-0 me-10 py-5  position-relative "+(tab === 'services' ? "active" : "")} onClick={() => setTab('services')}>
                            Serviços
                        </a>
                    }
                    {
                        checkPermission(auth.permissions,  'operators.list') && <a style={{cursor:'pointer'}}  className={"nav-link text-active-primary ms-0 me-10 py-5  position-relative "+(tab === 'operators' ? "active" : "")} onClick={() => setTab('operators')}>
                            Operadoras
                        </a>
                    }
                    {
                        checkPermission(auth.permissions,  'bankAccount.list') && <a style={{cursor:'pointer'}}  className={"nav-link text-active-primary ms-0 me-10 py-5  position-relative "+(tab === 'bankAccounts' ? "active" : "")} onClick={() => setTab('bankAccounts')}>
                            Contas Bancarias
                        </a>
                    }
                </li>
            </ul>
            <div className="tab-content" >
                <div className={"tab-pane fade show "+(tab === 'config' ? "active" : "")}>
                    {
                        data?.id > 0 && loading === false ?
                            <Company data={data} auth={auth} token={auth.token} company={auth.company.id} />
                            :
                            <div className="card">
                                <div className="card-header">
                                    <h1  className="card-title">Configuração da clínica</h1>
                                </div>
                                <div className="card-body">
                                    <FormSkeletonLoader />
                                </div>
                            </div>
                    }
                </div>
                {
                    data.type === 1 ?
                        <div className={"tab-pane fade show "+(tab === 'cert' ? "active" : "")}>
                            {
                                data?.id > 0 && loading === false ?
                                    <Certificate getCompany={getCompany} data={data} openAny={openAny} token={auth.token} company={auth.company.id} />
                                    :
                                    <div className="card">
                                        <div className="card-header">
                                            <h1  className="card-title">Configuração para gerar nota fiscal</h1>
                                        </div>
                                        <div className="card-body">
                                            <FormSkeletonLoader />
                                        </div>
                                    </div>
                            }

                            <Services />
                        </div>
                        :
                        <> </>
                }
                <div className={"tab-pane fade show "+(tab === 'profile' ? "active" : "")}>
                    <Profile />
                </div>
                <div className={"tab-pane fade show "+(tab === 'professionals' ? "active" : "")}>
                    <UserController />
                </div>
                <div className={"tab-pane fade show "+(tab === 'treatment' ? "active" : "")}>
                    <TreatmentController />
                </div>
                <div className={"tab-pane fade show "+(tab === 'services' ? "active" : "")}>
                    <ServicesController />
                </div>
                <div className={"tab-pane fade show "+(tab === 'operators' ? "active" : "")}>
                    <OperatorsController     />
                </div>
                <div className={"tab-pane fade show "+(tab === 'bankAccounts' ? "active" : "")}>
                    <BankAccountsController  />
                </div>
            </div>
        </>
    )
}

const  mapStateToProps = null;

const mapDispatchToProps = dispatch => ({
    openAny: (children,title,size) => dispatch(openAny(children,title,size)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Config);