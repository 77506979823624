import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../Field";

const Filter =
    ({
         setTypeDate,
         typeDate,
         setNota,
         nota,
         setDateEnd,
         dateEnd,
         setDateStart,
         dateStart,
         setTypePayment,
         typePayment,
         permission,
         start,
         typePayments,
         bankAccountId,
         setBankAccountId
    }) => {
    const options = typePayments.map(element => ({label: element.description, value: element.id}));
    options.unshift({label: 'Todos', value: 0});

    const dispatch = useDispatch();

    useEffect(() =>{
        dispatch({type:'GET_BANK_ACCOUNT'});
    },[])

    const bankAccounts = useSelector(state => state.companyBankAccount.items);
    const bankAccountsOptions = bankAccounts?.map(value => ({label:value.bank?.bank+" (Agência: "+value.agency+" e Conta: "+value.account+")",value:value.id}))
    bankAccountsOptions.unshift({label: 'Todos', value: 0});

    const {control, handleSubmit} = useForm({
        defaultValues: {
            typeDate: typeDate,
            nota: nota,
            dateStart: dateStart,
            dateEnd: dateEnd,
            typePayment: typePayment,
            bankAccountId:bankAccountId
        }
    });

    const onSubmit = (data) => {
        if (data.nota) setNota(data.nota);
        if (data.typePayment) setTypePayment(data.typePayment);
        if (data.typeDate) setTypeDate(data.typeDate);
        if (data.dateEnd) setDateEnd(data.dateEnd)
        if (data.dateStart) setDateStart(data.dateStart)
        if (data.dateStart) setDateStart(data.dateStart)
        if (data.bankAccountId) setBankAccountId(data.bankAccountId)
        start(data);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className='row'>
                <div className='col-md-3' style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Tipo:</label>
                    <Controller
                        name="typeDate"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field
                                type="select"
                                options={[
                                    {label: 'Data de Vencimento', value: 1},
                                    {label: 'Data de Pagamento', value: 2}
                                ]}
                                onChange={onChange}
                                value={value}
                            />
                        }
                    />

                </div>
                <div className='col-md-3' style={{marginTop: 5}}>
                    <label
                        className="fw-bold text-muted"
                    >
                        {permission === 'pay' ? "Tipo de conta" : "Forma de pagamento"}:
                    </label>
                    <Controller
                        name="typePayment"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field type="select" options={options} onChange={onChange} value={value}/>
                        }
                    />
                </div>
                <div className='col-md-3' style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Início:</label>
                    <Controller
                        name="dateStart"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field type="date" onChange={onChange} value={value}/>
                        }
                    />
                </div>
                <div className='col-md-3' style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Fim:</label>
                    <Controller
                        name="dateEnd"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field type="date" onChange={onChange} value={value}/>
                        }
                    />
                </div>
                <div className='col-md-3' style={{marginTop: 5}}>
                    <label className="fw-bold text-muted">Conta bancária:</label>
                    <Controller
                        name="bankAccountId"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Field type="select" options={bankAccountsOptions} onChange={onChange} value={value}/>
                        }
                    />
                </div>
                {
                    permission === 'receive' ? <div className='col-md-3' style={{marginTop: 5}}>
                        <label className="fw-bold text-muted">Nota:</label>
                        <Controller
                            name="nota"
                            control={control}
                            render={({field: {onChange, value}}) =>
                                <Field
                                    type="select"
                                    options={[
                                        {value: 0, label: 'Todos'},
                                        {value: 2, label: 'Anexada'},
                                        {value: 1, label: 'Solicitada'},
                                        {value: 3, label: 'Não solicitada'}
                                    ]}
                                    onChange={onChange}
                                    value={value}
                                />
                            }
                        />

                    </div> : <div className='col-md-3' style={{marginTop: 5}}></div>
                }

                <div className='col-md-3' style={{marginTop: 5}}>
                </div>
                <div className="col-md-3" style={{marginTop: 25}}>
                    <button className='btn btn-success btn-sm' style={{width: '100%'}}>
                        <span class="svg-icon svg-icon-muted svg-icon-1hx">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352c79.5 0 144-64.5 144-144s-64.5-144-144-144S64 128.5 64 208s64.5 144 144 144z"
                                    fill="#fff"
                                />
                            </svg>
                        </span>
                        Filtrar
                    </button>
                </div>
            </div>
        </form>

    )
}

export default Filter;