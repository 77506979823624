import { put, takeEvery, select,debounce } from 'redux-saga/effects';
import api from '../../api';
import { push } from 'connected-react-router';
import {
    professionalSuccess,
    setProfessional,
    professionalStartRequest,
    professionalFailRequest,
    professionalloading,
    professionalSuccessAutocomplete,
    professionalloadingAutocomplete,
    alterProfessional,
    setInfinityLoadingProfessional,
    professionalClear
} from '../actions/professionalsActions';
import { closeModal } from '../actions/uiActions';
import { messenge } from './utils';
import {alterAuthSuccess} from "../actions/authActions";
import toast from "react-hot-toast";

export const getAuthToken = (state) => state.auth.token;
export const getAuth = (state) => state.auth;

function* getProfessional(action){
    try{
        yield put(professionalClear())
        yield put(professionalloading(true));
        const response = yield  api.get('/user/'+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setProfessional(response.data));

        }
    } catch (e) {
        console.error(e);
        yield put(professionalloading(false));
    }
}

function* getProfessionals(action){

    try{
        yield put(professionalSuccess([],0));
        yield put(professionalloading(true));

        const response = yield  api.get('/user/type/'+action.typeUser+"?page="+action.page+"&pageSize="+action.perPage,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){

            yield put(professionalSuccess(response.data.data,response.data.total));
        }
    } catch (e) {
        console.error(e)
        yield put(professionalloading(false));
    }
}

function* infinityLoadingProfessionals(action){

    try{
        const response = yield  api.get('/user/type/'+action.typeUser+"?page="+action.page+"&pageSize="+action.perPage,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(setInfinityLoadingProfessional(response.data.data));
        }
    } catch (e) {
        console.error(e);
    }
}

function* searchProfessional(action){
    try{
        yield put(professionalloading(true));
        const response = yield  api.post('/user/search/type/'+action.typeUser+"?page="+action.page+"&pageSize="+action.perPage,{name:action.name},{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            if(response.data.data.length === 0){
                yield messenge({message:'Sem resultados para a pesquisa.'},'warning')
            }
            yield put(professionalSuccess(response.data.data,response.data.total));
        }
    } catch (e) {
        yield put(professionalloading(false));
        console.error(e);
    }
}

function* autocompleteProfessional(action){
    try{

        yield put(professionalloadingAutocomplete(true));
        const response = yield  api.post('/user/search/type/'+action.typeUser+"?page="+action.page+"&pageSize="+action.perPage,{name:action.name},{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            if(response.data.data.length === 0){
                yield messenge({message:'Sem resultados para a pesquisa.'},'warning')
            }
            yield put(professionalSuccessAutocomplete(response.data.data));
        }
    } catch (e) {
        yield put(professionalloadingAutocomplete(false));
        console.error(e);
    }
}

function* update(action){
    try{
        const auth = yield select(getAuth);

        let response =  api.put('/user/'+action.id,action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        toast.promise(response, {
            loading: auth.id === action.id ? 'Atualizando dados' : 'Atualizando o profissional.',
            success: (success) => {
                return success.data.message
            },
            error: (error) => {
                return error.response.data.message
            }
        })
        response = yield response
        if(response.status === 200){
            yield put(alterProfessional(response.data.user));
            if(auth.id === response.data.user.id){
                yield put(alterAuthSuccess(response.data.user))
            }
        }
    } catch (e) {
        console.error(e);
    }
}

function* store(action){
    try{
        yield put(professionalStartRequest());
        const response = yield  api.post('/user',action.data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield messenge(response.data.message,'success')
            yield put(professionalFailRequest());
            yield put(closeModal());
            yield put(push('/'+action.typeModel+'/'+response.data.data.id));
        }
    } catch (e) {
        console.error(e)
        yield messenge(e.response.data,'warning')
        yield put(professionalFailRequest());
    }
}

function* destroy(action){
    try{

        const response = yield  api.delete('/user/'+action.id,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){

            yield messenge(response.data,'success')

            if(action.typeModel === 'user'){
                yield getProfessionals({typeUser:1,page:1,perPage:100}); // buscando os responsáveis pelo paciente.
            }
        }
    } catch (e) {
        yield messenge(e.response.data,'warning')
    }
}


function* uploadPhoto(action){
    try{
        let data = new FormData();
        data.append('photo', action.data);

        const response = yield  api.post('/user/'+action.id+"/photo",data,{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(alterProfessional({img:response.data.url}))
            yield messenge(response.data,'success');
        }
    } catch (e) {
        yield messenge(e.response.data,'warning')
    }
}

function* deletePhoto(action){

    try{
        const response = yield  api.delete('/user/'+action.id+"/photo",{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield messenge(response.data,'success') ;
            yield put(alterProfessional({img:''}))
        }
    } catch (e) {
        yield messenge(e.response.data,'warning');
    }
}

export function* ProfessionalsSaga() {
    yield takeEvery('GET_PROFESSIONALS', getProfessionals);
    yield takeEvery('GET_PROFESSIONAL', getProfessional);
    yield takeEvery('INFINITYLOADING_PROFESSIONALS', infinityLoadingProfessionals);
    yield debounce(1000,'AUTOCOMPLETE_PROFESSIONAL', autocompleteProfessional);
    yield debounce(1000,'SEARCH_PROFESSIONAL', searchProfessional);
    yield takeEvery('UPDATE_PROFESSIONAL', update);
    yield takeEvery('STORE_PROFESSIONAL', store);
    yield takeEvery('DESTROY_PROFESSIONAL', destroy);
    yield takeEvery('UPLOAD_FOTO_PROFESSIONAL',uploadPhoto);
    yield takeEvery('DELETE_FOTO_PROFESSIONAL',deletePhoto);

}
