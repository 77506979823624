import React, {useRef} from 'react';
import {connect} from 'react-redux';
import Modal from '../components/modal/Modal';
import {
    getAutocompletePatient,
    getAddress,
    getForCep,
    getTypeAddress,
    getTypeResponsible,
    storeAddress,
    storePatient,
    updateAddress,
    updatePatient,
    getPatientPrivate,
    getUserPrivateStatus,
    updatePatientPrivate,
    getPatientTasks,
    destroyPatientTasks,
    getAnamnese,
    getEvolution,
    getResponsible, setCallArrayEvolution, setCallArrayAnamnese
} from "../store/actions/patientsActions";
import {
    documentGenerate, getDiagnostic,
    getRooms,
    getSpecializations,
    getStatus,
    getTypeSchedules
} from '../store/actions/companiesActions';
import AddressModal from '../components/modal/AddressModal';
import AtendimentoModal from '../components/modal/AtendimentoModal';
import {
    destroyCall, markoffCall, newUpdateStatus, reschedule,setItensCallArray,
    storeCall,
    updateCall,
} from "../store/actions/calendarActions";
import ReportsModal from '../components/modal/ReportsModal';
import {download} from '../store/actions/reportsActions';
import PatientModal from '../components/modal/PatientModal';
import DocumentsModal from '../components/modal/DocumentsModal';
import {upload} from '../store/actions/documentsActions';
import PlanModal from '../components/modal/PlanModal';
import {storePlan, updatePlan} from '../store/actions/planActions';
import ToolsModal from '../components/modal/ToolsModal';
import DesmarcarAtendimentoModal from '../components/modal/DesmarcarAtendimentoModal';
import AlterPasswordModal from '../components/modal/AlterPasswordModal';
import {alterPassword} from '../store/actions/authActions';
import UserModal from '../components/modal/UserModal';
import {getProfession} from '../store/actions/professonActions';
import ResponsibleModal from '../components/modal/ResponsibleModal';
import {
    getAutocompleteProfessional,
    getInfinityLoadingProfessionals,
    storeProfessional,
    updateProfessional
} from '../store/actions/professionalsActions';
import ParentalModal from '../components/modal/ParentalModal';
import {storeOrientation, updateOrientation} from '../store/actions/orientationActions';
import ExpedientsModal from '../components/modal/ExpedientsModal';
import {storeExpedients, updateExpedients} from '../store/actions/expedientsActions';
import ProfileModal from '../components/modal/ProfileModal';
import {getPermissions, getProfiles, storeProfiles, updateProfiles} from '../store/actions/profilesActions';
import BloquearHorarioModal from '../components/modal/BloquearHorarioModal';
import ListaBloquearHorarioModal from '../components/modal/ListaBloquearHorarioModal';
import {destroyTimeBlock, getTimeBlock, storeTimeBlock} from '../store/actions/timeBlockActions';
import UserPrivateModal from '../components/modal/UserPrivateModal';
import ManagerCallsModal from '../components/modal/ManagerCallsModal';
import TreatmentModal from '../components/modal/TreatmentModal';
import {getTreatment, getTreatmentAll, storeTreatment, updateTreatment} from '../store/actions/treatmentActions';
import FinanceModal from '../components/modal/FinanceModal';
import {
    financeParcelasSuccess,
    financeReportPayment,
    getFinanceParcelas,
    getTypePayment,
    storeFinance,
    updateFinance
} from '../store/actions/financeActions';
import ReportPaymentModal from '../components/modal/ReportPaymentModal';
import LegendasModal from "../components/modal/LegendasModal";
import SessionModal from "../components/modal/SessionModal";
import {groupsCopy} from "../store/actions/groupsActions";
import ServiceModal from "../components/modal/ServiceModal";
import {getService, storeService, updateService} from "../store/actions/serviceActions";
import ConfirmCallsModal from "../components/modal/ConfirmCallsModal";
import RemarcarAtendimentoModal from "../components/modal/RemarcarAtendimentoModal";
import ReportsView from "../components/modal/ReportsView";
import DiagnosticModal from "../components/modal/DiagnosticModal";
import FinanceDocumentModal from "../components/modal/FinanceDocumentModal";
import AuditModal from "../components/modal/AuditModal";
import CompanyModal from "../components/modal/CompanyModal";
import MarkOffCallsModal from "../components/modal/MarkOffCallsModal";
import ConfirmDestroyModal from "../components/modal/ConfirmDestroyModal";
import OperatorsModal from "../components/modal/OperatorsModal";
import {getOperator, getOperators, storeOperator, updateOperator} from "../store/actions/operatorsActions";
import HealthInsuranceModal from "../components/modal/HealthInsuranceModal";
import {storeHealthInsurance, updateHealthInsurance} from "../store/actions/healthInsuranceActions";
import UserProfessionModal from "../components/modal/UserProfessionModal";
import {
    storeUserProfession,
    updateUserProfession,
} from "../store/actions/userProfessionActions";
import PendenciesModal from "../components/modal/PendenciesModal";
import SignatureModal from "../components/modal/SignatureModal";
import PendenciesTherapeuticModal from "../components/modal/PendenciesTherapeuticModal";
import CertificateModal from "../components/modal/CertificateModal";
import {certificate, certificateDestroy, signature} from "../store/actions/signatureActions";
import { openAny, openViewPDF} from "../store/actions/uiActions";
import PendenciesCallsNotSignedModal from "../components/modal/PendenciesCallsNotSignedModal";
import QueueWaitModal from "../components/modal/QueueWaitModal";
import {storeQueueWait, updateQueueWait} from "../store/actions/queueWaitActions";
import BankAccountsModal from "../components/modal/BankAccountsModal";
import {storeBankAccount, updateBankAccount} from "../store/actions/companyBankAccountActions";
import PieChartModal from "../components/modal/PieChartModal";
import ServiceCompanyModal from "../components/modal/ServiceCompanyModal";
import {getCompanyService, storeCompanyService, updateCompanyService} from "../store/actions/companyServiceActions";
import InvoiceEventModal from "../components/modal/InvoiceEventModal";
import InvoiceCancelModal from "../components/modal/InvoiceCancelModal";
import InvoiceStoreModal from "../components/modal/InvoiceStoreModal";


function ModalController({
                             zIndex,
                             isMobile,
                             modal,
                             modalOpen,
                             closeModal,
                             modalPayload,
                             callback,
                             patientsLoadingAutocomplete,
                             patientsAutocomplete,
                             getAutocompletePatient,
                             storePatient,
                             updatePatient,
                             loadingPatient,
                             patientPrivate,

                             getPatientPrivate,
                             getInfinityLoadingProfessionals,
                             infinityLoadingProfessionals,
                             professionalsAutocomplete,
                             professionalsLoadingAutocomplete,
                             getAutocompleteProfessional,
                             updateProfessional,
                             storeProfessional,
                             loadingProfessionalsRequest,
                             professionals,

                             storeAddress,
                             updateAddress,
                             patient,
                             loadingAddress,
                             getForCep,
                             auth,

                             typeschedules,
                             getTypeSchedules,
                             address,
                             getAddress,
                             rooms,
                             getRooms,
                             storeCall,
                             updateCall,
                             loadingCalendar,
                             download,

                             upload,
                             loadingDocument,
                             storePlan,
                             updatePlan,
                             loadingPlan,

                             storeOrientation,
                             updateOrientation,
                             loadingOrientation,

                             documentGenerate,
                             loadingTools,
                             getTypeResponsible,
                             typeResponsible,
                             getStatus,
                             status,
                             typeAddress,
                             getTypeAddress,
                             searchLoading,
                             alterPassword,
                             loadingAuth,
                             getProfession,
                             profession,
                             storeExpedients,
                             updateExpedients,
                             expedientsLoadingAction,
                             loadingActionProfile,
                             updateProfiles,
                             storeProfiles,
                             getProfiles,
                             profiles,

                             timeBlocks,
                             getTimeBlock,
                             storeTimeBlock,
                             loadingTimeBlocksRequest,
                             destroyTimeBlock,
                             loadingTimeBlocks,

                             getUserPrivateStatus,
                             userPrivateStatus,
                             updatePatientPrivate,
                             patientPrivateLoadingAction,

                             getPatientTasks,
                             patientTasks,
                             patientTasksLoading,
                             destroyPatientTasks,

                             treatmentAll,
                             treatment,
                             getTreatmentAll,
                             updateTreatment,
                             storeTreatment,
                             loadingTreatment,
                             getTreatment,

                             getTypePayment,
                             storeFinance,
                             typePayments,
                             loadingFinanceAction,
                             updateFinance,
                             financeReportPayment,
                             getFinanceParcelas,
                             parcelas,
                             parcelasLoading,
                             financeParcelasSuccess,
                             groupsCopy,
                             openModal,
                             calendarRef,
                             destroyCall,
                             updateService,
                             storeService,
                             getService,
                             services,
                             getEvolution,
                             getAnamnese,
                             getResponsible,
                             responsibles,
                             setCallArrayEvolution,
                             setCallArrayAnamnese,
                             setItensCallArray,
                             markoffCall,
                             getDiagnostic,
                             diagnostics,
                             newUpdateStatus,
                             reschedule,
                             storeOperator,
                             updateOperator,
                             storeHealthInsurance,
                             updateHealthInsurance,
                             getOperator,
                             getOperators,
                             operators,
                             operatorItems,
                             userPrivate,
                             storeUserProfession,
                             updateUserProfession,
                             certificate,
                             certificateDestroy,
                             signature,
                             openViewPDF,
                             updateQueueWait,
                             storeQueueWait,
                             openAny,
                             updateBankAccount,
                             storeBankAccount,
                             storeCompanyService,
    updateCompanyService,
                             getCompanyService,
                             companyService
}) {
    const modalRef = useRef(null);

    if (!modal || !modalOpen) {
        return null;
    }

    let modalBody = null;
    let modalConfig = {icon: null, title: null, size: null}

    switch (modal) {
        case 'address':
            modalBody =
                <AddressModal searchLoading={searchLoading} typeAddress={typeAddress} getTypeAddress={getTypeAddress}
                              getForCep={getForCep} loading={loadingAddress} updateAddress={updateAddress}
                              data={modalPayload} storeAddress={storeAddress} patient={patient}/>
            modalConfig = {icon: null, title: 'Endereço', size: 'lg'}
            break;
        case 'atendimento':
            modalBody = <AtendimentoModal
                setCallArrayEvolution={setCallArrayEvolution}
                setCallArrayAnamnese={setCallArrayAnamnese}
                getService={getService}
                services={services}
                calendarRef={callback}
                destroyCall={destroyCall}
                patientsAutocomplete={patientsAutocomplete}
                patientsLoadingAutocomplete={patientsLoadingAutocomplete}
                getAutocompletePatient={getAutocompletePatient}
                professionalsAutocomplete={professionalsAutocomplete}
                professionalsLoadingAutocomplete={professionalsLoadingAutocomplete}
                getAutocompleteProfessional={getAutocompleteProfessional}
                updateCall={updateCall}
                data={modalPayload}
                storeCall={storeCall}
                auth={auth}
                rooms={rooms}
                getRooms={getRooms}
                loadingCalendar={loadingCalendar}
                address={address}
                getAddress={getAddress}
                getSpecializations={getTreatment}
                specializations={treatment}
                typeschedules={typeschedules}
                getTypeSchedules={getTypeSchedules}
            />
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.edit ? 'Editar atendimento' : 'Cadastrar atendimento'
            }
            break;
        case 'bloquearHorario':
            modalBody = <BloquearHorarioModal
                professionalsAutocomplete={professionalsAutocomplete}
                professionalsLoadingAutocomplete={professionalsLoadingAutocomplete}
                getAutocompleteProfessional={getAutocompleteProfessional}
                updateCall={updateCall}
                data={modalPayload}
                store={storeTimeBlock}
                auth={auth}
                loading={loadingTimeBlocksRequest}
            />
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar bloqueio de horário' : 'Novo bloqueio de horário'
            }
            break;
        case 'listarbloquearHorario':
            modalBody = <ListaBloquearHorarioModal
                auth={auth}
                data={modalPayload}
                getTimeBlock={getTimeBlock}
                itens={timeBlocks}
                destroyTimeBlock={destroyTimeBlock}
                loading={loadingTimeBlocks}
            />
            modalConfig = {icon: null, title: 'Horários bloqueados', size: 'lg'}
            break;
        case 'report':
            modalBody = <ReportsModal data={modalPayload} download={download}/>
            modalConfig = {icon: null, title: 'Download', size: 'lg'}
            break;
        case 'tools':
            modalBody = <ToolsModal
                loading={loadingTools}
                data={modalPayload}
                value={modalPayload.value}
                mentions={modalPayload.mentions}
                documentGenerate={documentGenerate}
            />
            modalConfig = {icon: null, title: modalPayload.description, size: 'lg'}
            break;
        case 'patient':
            modalBody = <PatientModal
                userPrivate={userPrivate}
                data={modalPayload}
                store={storePatient}
                update={updatePatient}
                loading={loadingPatient}
                auth={auth}
                type="patient"
                getInfinityLoadingProfessionals={getInfinityLoadingProfessionals}
                infinityLoadingProfessionals={infinityLoadingProfessionals}
                professionals={professionals}
                getPatientPrivate={getPatientPrivate}
                patientPrivate={patientPrivate}
            />
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar Paciente' : 'Novo Paciente',
                size: 'lg'
            }
            break;
        case 'user':
            modalBody =
                <UserModal profiles={profiles} getProfiles={getProfiles} data={modalPayload} store={storeProfessional}
                           update={updateProfessional} loading={loadingProfessionalsRequest} auth={auth}
                           getProfession={getProfession} profession={profession}/>
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? modalPayload.myData ? 'Meus dados' : 'Editar Usuário' : 'Novo Usuário',
                size: 'lg'
            }
            break;
        case 'document':
            modalBody = <DocumentsModal data={modalPayload} auth={auth} upload={upload} loading={loadingDocument}/>
            modalConfig = {icon: null, title: 'Enviar arquivos', size: ''}
            break;
        case 'plan':
            modalBody = <PlanModal loading={loadingPlan} data={modalPayload} storePlan={storePlan} auth={auth}
                                   updatePlan={updatePlan}/>
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar Objetivo' : 'Novo Objetivo',
                size: 'lg'
            }
            break;
        case 'parental':
            modalBody =
                <ParentalModal loading={loadingOrientation} data={modalPayload} store={storeOrientation} auth={auth}
                               update={updateOrientation}/>
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar Orientação' : 'Nova Orientação',
                size: 'lg'
            }
            break;
        case 'responsible':
            modalBody = <ResponsibleModal data={modalPayload} store={storePatient} update={updatePatient}
                                          loading={loadingPatient} auth={auth} getTypeResponsible={getTypeResponsible}
                                          typeResponsible={typeResponsible}/>
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar Responsável' : 'Novo Responsável',
                size: 'lg'
            }
            break;
        case 'desmarcar':
            modalBody =
                <DesmarcarAtendimentoModal  markoffCall={markoffCall} data={modalPayload}
                                           status={status} auth={auth} getStatus={getStatus}/>
            modalConfig = {icon: null, title: 'Desmarcar atendimento'}
            break;
        case 'markOffCallsModal':
            modalBody =
                <MarkOffCallsModal
                    handlerUpdateStatus={newUpdateStatus}
                    modalPayload={modalPayload}
                    auth={auth}
                />
            modalConfig = {icon: null, title: 'Desmarcar atendimento'}
            break;
        case 'remarcar':
            modalBody = <RemarcarAtendimentoModal data={modalPayload} auth={auth} updateStatus={reschedule}/>
            modalConfig = {icon: null, title: 'Remarcar atendimento'}
            break;
        case 'alterPassword':
            modalBody = <AlterPasswordModal alterPassword={alterPassword} auth={auth} loading={loadingAuth}/>
            modalConfig = {icon: null, title: 'Alterar senha', size: 'lg'}
            break;
        case 'expedients':
            modalBody =
                <ExpedientsModal alterPassword={alterPassword} data={modalPayload} loading={expedientsLoadingAction}
                                 store={storeExpedients} update={updateExpedients}/>
            modalConfig = {icon: null, title: modalPayload && modalPayload.id ? 'Editar expediente' : 'Novo expediente'}
            break;
        case 'profile':
            modalBody = <ProfileModal loading={loadingActionProfile} data={modalPayload} update={updateProfiles}
                                      store={storeProfiles}/>
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar perfil' : 'Novo perfil',
                size: 'lg'
            }
            break;
        case 'userprivate':
            modalBody =
                <UserPrivateModal loading={patientPrivateLoadingAction} getUserPrivateStatus={getUserPrivateStatus}
                                  userPrivateStatus={userPrivateStatus} data={modalPayload}
                                  update={updatePatientPrivate}/>
            modalConfig = {icon: null, title: 'Editar Status', size: 'lg'}
            break;
        case 'managerCalls':
            modalBody =
                <ManagerCallsModal auth={auth} destroyPatientTasks={destroyPatientTasks} patientTasks={patientTasks}
                                   getPatientTasks={getPatientTasks} data={modalPayload}
                                   patientTasksLoading={patientTasksLoading}/>
            modalConfig = {icon: null, title: 'Gerenciar cadastro recorrente', size: 'lg'}
            break;
        case 'treatment':
            modalBody = <TreatmentModal
                loading={loadingTreatment}
                storeTreatment={storeTreatment}
                updateTreatment={updateTreatment}
                treatmentAll={treatmentAll}
                treatment={treatment}
                auth={auth}
                data={modalPayload}
                getTreatmentAll={getTreatmentAll}/>
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar tratamento' : 'Novo tratamento',
                size: 'lg'
            }
            break;
        case 'finance':
            modalBody = <FinanceModal
                getAddress={getAddress}
                address={address}
                companyService={companyService}
                getCompanyService={getCompanyService}
                getResponsible={getResponsible}
                responsibles={responsibles}
                financeParcelasSuccess={financeParcelasSuccess}
                parcelasLoading={parcelasLoading}
                parcelas={parcelas}
                getFinanceParcelas={getFinanceParcelas}
                financeReportPayment={financeReportPayment}
                loading={loadingFinanceAction}
                typePayments={typePayments}
                getTypePayment={getTypePayment}
                updateFinance={updateFinance}
                storeFinance={storeFinance}
                auth={auth}
                patientsAutocomplete={patientsAutocomplete}
                patientsLoadingAutocomplete={patientsLoadingAutocomplete}
                getAutocompletePatient={getAutocompletePatient}
                data={modalPayload}
            />
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar ' + (modalPayload.permission === 'pay' ? 'conta' : 'receita') : 'Nova ' + (modalPayload.permission === 'pay' ? 'conta' : 'receita'),
                size: 'lg'
            }
            break;
        case 'financeDocument':
            modalBody = <FinanceDocumentModal
                data={modalPayload}
            />
            modalConfig = {
                icon: null,
                title: 'Documentos',
                size: 'lg'
            }
            break;
        case 'reportPayment':
            modalBody = <ReportPaymentModal
                financeReportPayment={financeReportPayment}
                loading={loadingFinanceAction}
                typePayments={typePayments}
                getTypePayment={getTypePayment}
                data={modalPayload}
                getResponsible={getResponsible}
                responsibles={responsibles}
            />
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Informar ' + (modalPayload.permission === 'pay' ? ' pagamento' : ' recebimento') : ' '
            }
            break;
        case 'legendas':
            modalBody = <LegendasModal
                status={status}
                getStatus={getStatus}
            />
            modalConfig = {icon: null, title: 'Legendas'}
            break;
        case 'session':
            modalBody = <SessionModal
                openViewPDF={openViewPDF}
                isMobile={isMobile}
                closeModal={closeModal}
                setItensCallArray={setItensCallArray}
                getEvolution={getEvolution}
                getAnamnese={getAnamnese}
                getService={getService} services={services}
                calendarApi={callback}
                modalPayload={modalPayload}
                user={auth}
                groupsCopy={groupsCopy}
                openModal={openModal}
                destroyCall={destroyCall}
                calendarRef={calendarRef}
                patientsAutocomplete={patientsAutocomplete}
                patientsLoadingAutocomplete={patientsLoadingAutocomplete}
                getAutocompletePatient={getAutocompletePatient}
                professionalsAutocomplete={professionalsAutocomplete}
                professionalsLoadingAutocomplete={professionalsLoadingAutocomplete}
                getAutocompleteProfessional={getAutocompleteProfessional}
                updateCall={updateCall}
                data={modalPayload}
                storeCall={storeCall}
                rooms={rooms}
                getRooms={getRooms}
                loadingCalendar={loadingCalendar}
                address={address}
                getAddress={getAddress}
                getSpecializations={getTreatment}
                specializations={treatment}
                typeschedules={typeschedules}
                getTypeSchedules={getTypeSchedules}
                getResponsible={getResponsible}
                responsibles={responsibles}
                setCallArrayEvolution={setCallArrayEvolution}
                setCallArrayAnamnese={setCallArrayAnamnese}
            />
            modalConfig = {icon: null, title: modalPayload.type_schedule.description, size: 'lg', color: '#f5f5f5'}
            break;
        case 'services':
            modalBody = <ServiceModal data={modalPayload} updateService={updateService} storeService={storeService}/>
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar serviço' : 'Cadastrar serviço',
                size: 'lg'
            }
            break;
        case 'confirmCalls':
            modalBody =
                <ConfirmCallsModal
                    data={modalPayload}
                    getResponsible={getResponsible}
                    responsibles={responsibles}
                    calendarRef={calendarRef}
                />
            modalConfig = {icon: null, title: 'Confirmar atendimento', size: 'lg'}
            break;
        case 'reportsView':
            modalBody =
                <ReportsView
                    data={modalPayload}
                />
            modalConfig = {icon: null, title: modalPayload.description, size: 'lg'}
            break;
        case 'diagnosticModal':
            modalBody =
                <DiagnosticModal
                    data={modalPayload}
                    getDiagnostic={getDiagnostic}
                    diagnostics={diagnostics}
                />
            modalConfig = {icon: null, title:  modalPayload.id ? 'Editar diagnóstico' : 'Cadastrar diagnóstico'}
            break;
        case 'auditModal':
            modalBody =
                <AuditModal
                    data={modalPayload}
                />
            modalConfig = {icon: null, title: 'Auditoria',size: 'lg'}
            break;
        case 'companyModal':
            modalBody =
                <CompanyModal
                    data={modalPayload}
                />
            modalConfig = {icon: null, title: 'Cadastrar empresa',size: 'lg'}
            break
        case 'confirmDestroyModal':
            modalBody =
                <ConfirmDestroyModal
                    modalPayload={modalPayload}
                    destroyCall={destroyCall}
                    closeModal={closeModal}
                />
            modalConfig = {icon: null, title: 'Deseja realmente excluir?'}
            break;
        case 'operatorsModal':
            modalBody = <OperatorsModal operatorItems={operatorItems} data={modalPayload} updateOperator={updateOperator} getOperators={getOperators} operators={operators} storeOperator={storeOperator}/>
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar operadora' : 'Cadastrar operadora',
                size: 'lg'
            }
            break;
        case 'healthInsuranceModal':
            modalBody = <HealthInsuranceModal operators={operatorItems} getOperator={getOperator} data={modalPayload} updateHealthInsurance={updateHealthInsurance} storeHealthInsurance={storeHealthInsurance}/>
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar convênio' : 'Cadastrar convênio',
                size: 'lg'
            }
            break;
        case 'userProfession':
            modalBody = <UserProfessionModal data={modalPayload} storeUserProfession={storeUserProfession} updateUserProfession={updateUserProfession} profession={profession} getProfession={getProfession} />
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar profissão' : 'Cadastrar profissão',
                size: 'lg'
            }
            break;
        case 'pendenciesModal':
            modalBody = <PendenciesModal
                data={modalPayload}
                isMobile={isMobile}
                modalRef={modalRef}
            />
            modalConfig = {
                icon: null,
                title: 'Atendimentos pendentes',
                size: 'lg',
                type:'drawers'
            }
            break;
        case 'pendenciesTherapeuticModal':
            modalBody = <PendenciesTherapeuticModal
                data={modalPayload}
                isMobile={isMobile}
                modalRef={modalRef}
            />
            modalConfig = {
                icon: null,
                title: 'Plano Terapêutico Desatualizados',
                size: 'lg',
                type:'drawers'
            }
            break;
        case 'signatureModal':
            modalBody = <SignatureModal
                modalPayload={modalPayload}
                isMobile={isMobile}
                modalRef={modalRef}
                auth={auth}
                closeModal={closeModal}
                signature={signature}

            />
            modalConfig = {
                icon: null,
                title: 'Documento aguardando assinatura.',
                size: 'lg',
                type:'drawers',
                color: '#fff'
            }
            break;
        case 'certificateModal':
            modalBody = <CertificateModal
                modalPayload={modalPayload}
                auth={auth}
                certificate={certificate}
                certificateDestroy={certificateDestroy}
                openAny={openAny}
            />
            modalConfig = {
                icon: null,
                title: 'Meu certificado',

            }
            break;
        case 'pendenciesCallsNotSignedModal':
            modalBody = <PendenciesCallsNotSignedModal
                data={modalPayload}
                isMobile={isMobile}
                modalRef={modalRef}
            />
            modalConfig = {
                icon: null,
                title: 'Atendimentos com assinatura pendentes',
                size: 'lg',
                type:'drawers'
            }
            break;
        case 'queueWaitModal':
            modalBody =
                <QueueWaitModal
                    data={modalPayload}
                    getTreatment={getTreatment}
                    treatment={treatment}
                    storeQueueWait={storeQueueWait}
                    updateQueueWait={updateQueueWait}
                />
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar tratamento na fila de espera' : 'Cadastrar tratamento na fila de espera.',
                size: 'lg'
            }
            break;
        case 'bankAccountsModal':
            modalBody =
                <BankAccountsModal
                    data={modalPayload}
                    updateBankAccount={updateBankAccount}
                    storeBankAccount={storeBankAccount}
                />
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar conta bancária.' : 'Cadastrar conta bancária.',
                size: 'lg'
            }
            break;
        case 'PieChartModal':
            modalBody =
                <PieChartModal
                    data={modalPayload}
                />
            modalConfig = {
                icon: null,
                title: modalPayload.title,
                size: 'lg',

            }
            break;

        case 'serviceCompanyModal':
            modalBody =
                <ServiceCompanyModal
                    data={modalPayload}
                    getTreatment={getTreatment}
                    treatment={treatment}
                    storeCompanyService={storeCompanyService}
                    updateCompanyService={updateCompanyService}
                    updateQueueWait={updateQueueWait}
                />
            modalConfig = {
                icon: null,
                title: modalPayload && modalPayload.id ? 'Editar serviço' : 'Cadastrar serviço',
                size: 'lg'
            }

            break;
        case 'invoiceEventModal':
            modalBody =
                <InvoiceEventModal
                    data={modalPayload}
                />
            modalConfig = {
                icon: null,
                title: 'Eventos da nota',
                size: 'lg',
                type:"drawers"
            }
            break;
            case 'invoiceCancelModal':
            modalBody =
                <InvoiceCancelModal
                    id={modalPayload.id}
                    closeModal={closeModal}
                />
            modalConfig = {
                icon: null,
                title: 'Cancelar nota',

            }
            break;
        case 'invoiceStoreModal':
            modalBody =
                <InvoiceStoreModal
                    data={modalPayload}
                    closeModal={closeModal}
                />
            modalConfig = {
                icon: null,
                title: 'Deseja criar uma nota?',

            }
            break;
        default:
            break;
    }

    return (
        <>
            <Modal
                fullscreen={modalConfig.fullscreen}
                isMobile={isMobile}
                closeModal={closeModal}
                icon={modalConfig.icon}
                title={modalConfig.title}
                type={modalConfig.type}
                size={modalConfig.size}
                color={modalConfig.color}
                zIndex={zIndex}
                modalRef={modalRef}
            >
                {modalBody}
            </Modal>
        </>
    );
}


const mapStateToProps = state => ({
    callback: state.ui.callback,

    patient: state.patients.item,
    patientsAutocomplete: state.patients.autocomplete,
    patientsLoadingAutocomplete: state.patients.loadingAutocomplete,
    loadingPatient: state.patients.loadingRequest,
    patientPrivate: state.patients.patientPrivate,

    infinityLoadingProfessionals: state.professionals.loadingInfinity,
    professionals: state.professionals.itens,
    professionalsAutocomplete: state.professionals.autocomplete,
    professionalsLoadingAutocomplete: state.professionals.loadingAutocomplete,
    loadingProfessionalsRequest: state.professionals.loadingRequest,

    loadingAddress: state.patients.addressRequestLoading,
    auth: state.auth,

    specializations: state.companies.specializations,

    typeschedules: state.companies.typeschedules,

    address: state.patients.address,

    rooms: state.companies.rooms,

    loadingCalendar: state.calendar.loadingRequest,

    loadingDocument: state.documents.loadingRequest,

    loadingPlan: state.plan.loadingRequest,

    loadingOrientation: state.orientations.loadingRequest,

    loadingTools: state.companies.toolsDocumentLoading,

    typeResponsible: state.patients.typeResponsible,

    status: state.companies.status,

    typeAddress: state.patients.typeAddress,
    searchLoading: state.patients.addressLoadingSearch,

    loadingAuth: state.auth.loading,

    profession: state.profession.itens,
    expedientsLoadingAction: state.expedients.loadingAction,
    loadingActionProfile: state.profiles.loadingAction,
    profiles: state.profiles.itens,
    timeBlocks: state.timeBlock.itens,
    loadingTimeBlocksRequest: state.timeBlock.loadingRequest,
    loadingTimeBlocks: state.timeBlock.loading,

    userPrivateStatus: state.patients.patientPrivateStatus,
    userPrivate: state.patients.patientPrivate,
    patientPrivateLoadingAction: state.patients.patientPrivateLoadingAction,

    patientTasks: state.patients.patientTasks,
    patientTasksLoading: state.patients.patientTasksLoading,

    treatmentAll: state.treatment.all,
    treatment: state.treatment.itens,
    loadingTreatment: state.treatment.loadingAction,

    typePayments: state.finance.typePayments,
    loadingFinanceAction: state.finance.loadingAction,
    parcelas: state.finance.parcelas,
    parcelasLoading: state.finance.parcelasLoading,

    services: state.service.itens,
    responsibles: state.patients.responsible ? state.patients.responsible.itens : [],
    diagnostics: state.companies.diagnostics,
    operatorItems: state.operators.items,
    operators: state.operators.operators,
    companyService: state.companyService.items,
})

const mapDispatchToProps = dispatch => ({
    storeAddress: (data) => dispatch(storeAddress(data)),
    updateAddress: (id, data) => dispatch(updateAddress(id, data)),
    getForCep: (cep, setValue) => dispatch(getForCep(cep, setValue)),
    getAddress: (patient) => dispatch(getAddress(patient)),
    getTypeAddress: () => dispatch(getTypeAddress()),
    getPatientPrivate: (id) => dispatch(getPatientPrivate(id)),
    getSpecializations: (professional) => dispatch(getSpecializations(professional)),
    getTypeSchedules: () => dispatch(getTypeSchedules()),
    getRooms: () => dispatch(getRooms()),
    storeCall: (data, location) => dispatch(storeCall(data, location)),
    updateCall: (data, location) => dispatch(updateCall(data, location)),

    download: (report, query) => dispatch(download(report, query)),

    upload: (file, company, patient) => dispatch(upload(file, company, patient)),

    storePlan: (data) => dispatch(storePlan(data)),
    updatePlan: (data, id) => dispatch(updatePlan(data, id)),

    storeOrientation: (data) => dispatch(storeOrientation(data)),
    updateOrientation: (data, id) => dispatch(updateOrientation(data, id)),

    documentGenerate: (data) => dispatch(documentGenerate(data)),

    getTypeResponsible: () => dispatch(getTypeResponsible()),

    getStatus: () => dispatch(getStatus()),
    newUpdateStatus: (request) => dispatch(newUpdateStatus(request)),

    getProfession: () => dispatch(getProfession()),

    getAutocompletePatient: (typeUser, page, perPage, name) => dispatch(getAutocompletePatient(typeUser, page, perPage, name)),
    storePatient: (data, typeModel) => dispatch(storePatient(data, typeModel)),
    updatePatient: (data, id, type) => dispatch(updatePatient(data, id, type)),

    storeProfessional: (data, typeModel) => dispatch(storeProfessional(data, typeModel)),
    updateProfessional: (data, id, typeModel) => dispatch(updateProfessional(data, id, typeModel)),
    getAutocompleteProfessional: (typeUser, page, perPage, name) => dispatch(getAutocompleteProfessional(typeUser, page, perPage, name)),
    alterPassword: (id, password, password_current, password_confirmation) => dispatch(alterPassword(id, password, password_current, password_confirmation)),
    getInfinityLoadingProfessionals: (typeUser, page, perPage) => dispatch(getInfinityLoadingProfessionals(typeUser, page, perPage)),

    updateExpedients: (id, data) => dispatch(updateExpedients(id, data)),
    storeExpedients: (data) => dispatch(storeExpedients(data)),

    getPermissions: () => dispatch(getPermissions()),
    updateProfiles: (id, data) => dispatch(updateProfiles(id, data)),
    storeProfiles: (data) => dispatch(storeProfiles(data)),
    getProfiles: () => dispatch(getProfiles()),
    getTimeBlock: (id) => dispatch(getTimeBlock(id)),

    storeTimeBlock: (data) => dispatch(storeTimeBlock(data)),
    destroyTimeBlock: (id, professional) => dispatch(destroyTimeBlock(id, professional)),

    getUserPrivateStatus: () => dispatch(getUserPrivateStatus()),
    updatePatientPrivate: (data, id) => dispatch(updatePatientPrivate(data, id)),
    getPatientTasks: (user) => dispatch(getPatientTasks(user)),
    destroyPatientTasks: (id) => dispatch(destroyPatientTasks(id)),

    getTreatmentAll: () => dispatch(getTreatmentAll()),
    updateTreatment: (data, id) => dispatch(updateTreatment(data, id)),
    storeTreatment: (data) => dispatch(storeTreatment(data)),
    getTreatment: () => dispatch(getTreatment()),

    getTypePayment: (typeFinance) => dispatch(getTypePayment(typeFinance)),
    storeFinance: (data) => dispatch(storeFinance(data)),
    updateFinance: (data, id) => dispatch(updateFinance(data, id)),
    financeReportPayment: (id, data, typeFinance) => dispatch(financeReportPayment(id, data, typeFinance)),
    getFinanceParcelas: (value, parcelas) => dispatch(getFinanceParcelas(value, parcelas)),
    financeParcelasSuccess: (data) => dispatch(financeParcelasSuccess(data)),
    groupsCopy: (patient, field, typeSchedule, group, call, connection, table, id_link, treatment) => dispatch(groupsCopy(patient, field, typeSchedule, group, call, connection, table, id_link, treatment)),
    destroyCall: (data) => dispatch(destroyCall(data)),
    getAnamnese: (data) => dispatch(getAnamnese(data)),
    getEvolution: (data) => dispatch(getEvolution(data)),
    updateService: (data, id) => dispatch(updateService(data, id)),
    storeService: (data) => dispatch(storeService(data)),
    getService: () => dispatch(getService()),
    getResponsible: (id) => dispatch(getResponsible(id)),
    setCallArrayEvolution: (data) => dispatch(setCallArrayEvolution(data)),
    setCallArrayAnamnese: (data) => dispatch(setCallArrayAnamnese(data)),
    setItensCallArray: (data) => dispatch(setItensCallArray(data)),
    markoffCall: async (data) => dispatch(markoffCall(data)),
    getDiagnostic:() => dispatch(getDiagnostic()),
    reschedule:(data) => dispatch(reschedule(data)),
    updateOperator:(data,id) => dispatch(updateOperator(data,id)),
    storeOperator:(data) => dispatch(storeOperator(data)),
    updateHealthInsurance:(data,id) => dispatch(updateHealthInsurance(data,id)),
    storeHealthInsurance:(data) => dispatch(storeHealthInsurance(data)),
    getOperators:() => dispatch(getOperators()),
    getOperator:() => dispatch(getOperator()),
    storeUserProfession:(data) => dispatch(storeUserProfession(data)),
    updateUserProfession:(data,id) => dispatch(updateUserProfession(data,id)),
    certificate:(data) => dispatch(certificate(data)),
    certificateDestroy:(id) => dispatch(certificateDestroy(id)),
    signature:(data) => dispatch(signature(data)),
    openViewPDF:(viewPDFOpen,payloadViewPDF) => dispatch(openViewPDF(viewPDFOpen,payloadViewPDF)),
    storeQueueWait:(data) => dispatch(storeQueueWait(data)),
    updateQueueWait:(data,id) => dispatch(updateQueueWait(data,id)),
    openAny: (children,title,size) => dispatch(openAny(children,title,size)),
    updateBankAccount:(data,id) => dispatch(updateBankAccount(data,id)),
    storeBankAccount:(data) => dispatch(storeBankAccount(data)),
    storeCompanyService:(data) => dispatch(storeCompanyService(data)),
    updateCompanyService:(data,id) => dispatch(updateCompanyService(data,id)),
    getCompanyService:() => dispatch(getCompanyService())
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalController);