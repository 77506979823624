import { put, takeEvery, select} from 'redux-saga/effects';
import api from '../../api';
import toast from "react-hot-toast";
import {closeModal} from "../actions/uiActions";
import {companyServiceSuccess, setLoading} from "../actions/companyServiceActions";

export const getAuthToken = (state) => state.auth.token;

function* get(){
    try{
        yield put(setLoading(true))
        const response = yield  api.get('/companyService',{
            headers: {
                'Authorization': 'Bearer ' + (yield select(getAuthToken)),
            }
        });

        if(response.status === 200){
            yield put(companyServiceSuccess(response.data));
        }
    } catch (e) {
        console.error(e);
    }finally {
        yield put(setLoading(false));
    }
}

function* update(action){

    let response = api.put('/companyService/'+action.id, action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Atualizando o serviço...',
            success: (success) => {
                return 'Serviço atualizado com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível atualizar o serviço.'
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield get();
    }
}

function* store(action){
    let response = api.post('/companyService', action.data, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Cadastrando serviço...',
            success: (success) => {
                return 'Serviço cadastrado com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível cadastrar o serviço.'
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield put(closeModal());
        yield get();
    }
}

function* destroy(action){

    let response = api.delete('/companyService/'+action.id, {
        headers: {
            'Authorization': 'Bearer ' + (yield select(getAuthToken)),
        }
    });

    toast.promise(response,
        {
            loading: 'Deletando o serviço...',
            success: (success) => {
                return 'Serviço deletado com sucesso.'
            },
            error: (error) => {
                return 'Não foi possível deletar o serviço.'
            }
        }
    );

    response = yield response;
    if (response.status === 200) {
        yield put(closeModal());
        yield get();
    }
}

export function* companyServiceSaga() {
    yield takeEvery('GET_COMPANY_SERVICE', get);
    yield takeEvery('UPDATE_COMPANY_SERVICE', update);
    yield takeEvery('STORE_COMPANY_SERVICE', store);
    yield takeEvery('DESTROY_COMPANY_SERVICE', destroy);
}