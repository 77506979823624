import React from "react";
import {Controller, useForm} from "react-hook-form";
import {Field} from "../../../components/Field";
import Api from "../../../api";
import toast from "react-hot-toast";
import {Button} from "../../../components/Button";
import {formatDateNotHour} from "../../../utils";
import {DateTime} from "luxon";

export default function Certificate({getCompany,company,token,openAny,data}){

    const {control, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues:{
            ...data
        }
    });

    const changeHandler = (event) => {
        setValue('certificate',event.target.files[0]);
    };


    const destroyCertificate = async() => {

        const response = Api.put(
            "/company/"+company,
            {
                certificate:"",
                certificate_validity:"",
                certificate_terms:0
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });

        toast.promise(response,
            {
                loading: 'Removendo certificado...',
                success: (data) => {
                    return data.data
                },
                error: (error) => {
                    return error.response.data
                }
            }
        ).then(() => {
            getCompany()
        });
    }

    const onSubmit = async(request) => {

        let data = new FormData();
        data.append('password_certificate', request.password_certificate);
        data.append('certificate', request.certificate);
        data.append('terms', request.terms);
        data.append('rps_number', request.rps_number);
        data.append('rps_serie', request.rps_serie);
        data.append('lote', request.lote);

        const response = Api.post(
            "/company/"+company+"/certificate",
            data,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                }
            });

        toast.promise(response,
            {
                loading: 'Configurando a funcionalidade para gerar notas fiscais...',
                success: (data) => {
                    return data.data.message
                },
                error: (error) => {
                    return error.response.data.message
                }
            }
        ).then(() => getCompany());
    }


    let certificateValidityDate = DateTime.fromISO(data.certificate_validity);

    let now = DateTime.now();

    return (
        <div className="card">
            <div className="card-header">
                <h1  className="card-title">Configuração para gerar nota fiscal</h1>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        {
                            !data.certificate_terms ?
                                <>
                                    <div className="col-lg-6">
                                    <label className="fw-bold text-muted" style={{marginTop:10}}>Certificado:</label>
                                    <Controller
                                        name="certificate"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field : { onChange, onBlur, value }}) => <input   type="file" name="certificate" onChange={changeHandler} className='form-control' style={{height:38}}/>}
                                    />
                                    {errors.certificate && <p style={{color:'red',marginTop:10}}>Necessário selecionar o certificado.</p>}
                                </div>
                                    <div className="col-lg-3">
                                        <label className="fw-bold text-muted" style={{marginTop:10}}>Senha:</label>
                                        <Controller
                                            name="password_certificate"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field : { onChange, onBlur, value }}) => <Field  type="password" value={value}  onChange={(value) => {onChange(value);} }  />}
                                        />
                                        {errors.password_certificate && <p style={{color:'red',marginTop:10}}>Necessário preencher a senha do certificado.</p>}
                                    </div>
                                    <div className="col-lg-12">
                                        <div style={{display:"flex",marginTop:10}}>
                                            <Controller
                                                name="terms"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field : { onChange, onBlur, value }}) => <Field type="checkbox" value={value}  onChange={(value) => {onChange(value);} }  />}
                                            />
                                            <div
                                                style={{marginTop:12}}
                                            >
                                                Concordo com os
                                                <span
                                                    style={{cursor:"pointer",marginLeft:3,marginRight:3,fontWeight:"bold",color:"blue"}}
                                                    onClick={() => openAny(
                                                        <div style={{marginTop:30,textAlign:"justify"}}>
                                                            <h3>Termo de Aceite para Armazenamento de Certificado A1 para Assinatura Eletrônica</h3>
                                                            <br/>
                                                            <h4>1. Introdução</h4>
                                                            <p>Este Termo de Aceite ("Termo") estabelece os termos e condições para o armazenamento seguro do Certificado Digital A1 utilizado para assinatura eletrônica. Ao aceitar este Termo, você concorda com o armazenamento do Certificado Digital A1 nos servidores da Amazon Web Services (AWS) S3 utilizando o AWS Key Management Service (KMS) para criptografia, administrados pela <b>Wayfly Tecnologia & Informacao LTDA</b>.</p>
                                                            <br/>
                                                            <h4>2. Definições</h4>
                                                            <ul>
                                                                <li><b>Certificado Digital A1</b>: Certificado emitido por uma autoridade certificadora, utilizado para assinatura eletrônica.</li>
                                                                <li><b>AWS S3</b>: Serviço de armazenamento de objetos na nuvem fornecido pela Amazon Web Services.</li>
                                                                <li><b>AWS KMS</b>: Serviço de gerenciamento de chaves fornecido pela Amazon Web Services, utilizado para criptografar dados.</li>
                                                                <li><b>Wayfly Tecnologia & Informacao LTDA</b>: Empresa responsável pela administração e gerenciamento dos serviços AWS S3 e AWS KMS.</li>
                                                            </ul>
                                                            <br/>
                                                            <h4>3. Armazenamento Seguro</h4>
                                                            <p>3.1. O Certificado Digital A1 será armazenado nos servidores AWS S3, administrados pela <b>Wayfly Tecnologia & Informacao LTDA</b>, com criptografia gerenciada pelo AWS KMS.</p>
                                                            <p>3.2. Apenas o sistema de assinatura da <b>Wayfly Tecnologia & Informacao LTDA</b> terá acesso ao Certificado Digital A1. Nenhum usuário da <b>Wayfly Tecnologia & Informacao LTDA</b> terá acesso ao certificado.</p>
                                                            <br/>
                                                            <h4>4. Responsabilidade e Segurança</h4>

                                                            <p>4.1. A <b>Wayfly Tecnologia & Informacao LTDA</b> se compromete a implementar todas as medidas de segurança necessárias para proteger o Certificado Digital A1 contra acessos não autorizados, perdas e roubos.</p>

                                                            <p>4.2. Você é responsável por manter a confidencialidade das suas credenciais de acesso e por todas as atividades realizadas com seu certificado digital.</p>
                                                            <br/>
                                                            <h4>5. Consentimento</h4>

                                                            <p>Ao aceitar este Termo, você consente expressamente com o armazenamento do Certificado Digital A1 nos servidores da AWS S3, administrados pela <b>Wayfly Tecnologia & Informacao LTDA</b>, utilizando o AWS KMS para criptografia, conforme descrito acima.</p>
                                                            <br/>
                                                            <h4>6. Alterações no Termo</h4>

                                                            <p>A <b>Wayfly Tecnologia & Informacao LTDA</b> se reserva o direito de alterar este Termo a qualquer momento, mediante notificação prévia. A continuidade do uso do serviço após a alteração constitui aceitação das novas condições.</p>

                                                            <h4>7. Contato</h4>

                                                            <p>Para quaisquer dúvidas ou questões relacionadas a este Termo, entre em contato com nosso suporte técnico pelo e-mail contato@wayfly.com.br.</p>
                                                        </div>
                                                        ,'Termo de Aceite para Armazenamento'
                                                        ,'lg'
                                                        )}
                                                    >
                                                termos para salvar
                                                </span>
                                                o certificado.
                                            </div>
                                        </div>
                                        {errors.terms && <p style={{color:'red',marginTop:10}}>Necessário concordar com os termos.</p>}
                                    </div>
                                </>
                                :

                                <div className="col-lg-12" style={{display:"flex",borderRadius:10,border:"1px solid #f1f1f1",padding:20,paddingBottom:10,marginTop:10}}>
                                    <div style={{flex:1}}>
                                        <h4>certificado.pfx</h4>
                                        <p style={{color:"#b3b3b3"}}>
                                            {
                                                certificateValidityDate < now ?
                                                    <b>Seu certifcado venceu no dia: </b>
                                                    :
                                                    <b>Seu certifcado vence no dia: </b>
                                            }
                                            {formatDateNotHour(DateTime.fromISO(data.certificate_validity))}
                                        </p>
                                    </div>
                                    <div style={{paddingTop:10}}>
                                        <button type="button" className="btn btn-danger btn-sm" onClick={() => destroyCertificate()}>Excluir</button>
                                    </div>
                                </div>
                        }


                            <div className="col-md-2">
                                <label className="fw-bold text-muted" style={{marginTop:10}}>RPS Número:</label>
                                <Controller
                                    name="rps_number"
                                    control={control}
                                    render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                                />

                            </div>
                            <div className="col-md-2">
                                <label className="fw-bold text-muted" style={{marginTop:10}}>RPS Série:</label>
                                <Controller
                                    name="rps_serie"
                                    control={control}
                                    render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                                />
                            </div>
                            <div className="col-md-2">
                                <label className="fw-bold text-muted" style={{marginTop:10}}>Lote:</label>
                                <Controller
                                    name="lote"
                                    control={control}
                                    render={({ field : { onChange,  value }}) => <Field  type="text" value={value}  onChange={onChange} />}
                                />
                            </div>
                    </div>
                    <div className="row" style={{textAlign:'right',marginTop:20}}>
                        <div className="col-md-10">
                        </div>
                        <div className="col-md-2">
                            <Button text="Salvar" type="submit" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}