import React, {useEffect, useState} from "react";
import {checkPermission} from "../../utils";

export default function Button({ auth, permission, typeModel, value, openModal, setConfirmTask, setConfirmOne,setValue}) {
    const [novo, setNovo] = useState(false);

    useEffect(() => {
        function click(ev) {
            if (ev.srcElement.closest('#novoPlan') == null) {
                setNovo(false);
            }
        }

        if (novo) {
            window.addEventListener('click', click, false);
            return () => {
                window.removeEventListener('click', click, false);
            };
        }

    }, [novo]);

    const invoices = value.finance_document?.filter(value => value.status !== 12);
    const invoice = invoices?.length > 0 ?  invoices[invoices?.length - 1] : {};

    return (
        <>
            <button
                className="btn btn-sm btn-icon  btn-active-color-primary"
                style={{float: 'right'}}
                onClick={() => setNovo(true)}
            >
                <span className="svg-icon svg-icon-muted svg-icon-2hx">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="4" fill="currentColor"/>
                        <rect x="11" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                        <rect x="15" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                        <rect x="7" y="11" width="2.6" height="2.6" rx="1.3" fill="currentColor"/>
                    </svg>
                </span>
            </button>
            <div
                className={"dropdown-menu " + (novo ? "show" : '')}
                style={{marginTop: 40, right: 13}}
                id="novoPlan"
            >
                {
                    ![1,2,4].includes(invoice?.status) && checkPermission(auth.permissions, permission + '.update') &&
                    <a
                        className="dropdown-item"
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            openModal('finance', {...value, permission: permission, typeModel});
                            setNovo(false)
                        }}
                    >
                        Editar
                    </a>
                }
                {
                    (value && value.id && (value.status.id === 1 || value.status.id === 3)) && checkPermission(auth.permissions, permission + '.receiver') &&
                    <a

                        className="dropdown-item"
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            openModal('reportPayment', {...value, permission: permission, typeModel});
                            setNovo(false)
                        }}
                    >
                        {permission === "pay" ? "Informar pagamento" : "Informar recebimento"}
                    </a>
                }
                {
                    ![1,2,4].includes(invoice?.status) ?
                        value && value.id && value.status.id === 3 ?
                            checkPermission(auth.permissions, permission + '.destroy2') &&
                            <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {
                                setValue(value)
                                if (value.task > 0) {
                                    setConfirmTask(true);

                                } else {
                                    setConfirmOne(true);
                                }
                                setNovo(false)
                            }}>Excluir</a>
                            :
                            checkPermission(auth.permissions, permission + '.destroy') &&
                            <a className="dropdown-item" style={{cursor: 'pointer'}} onClick={() => {
                                setValue(value)
                                if (value.task > 0) {
                                    setConfirmTask(true);
                                } else {
                                    setConfirmOne(true);
                                }
                                setNovo(false)
                            }}>Excluir</a>
                        :
                        <></>
                }
                {
                    checkPermission(auth.permissions, permission + '.anexo') &&
                    <a

                        className="dropdown-item"
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            openModal('financeDocument', {...value, permission: permission, typeModel});
                            setNovo(false)
                        }}
                    >
                        Documentos
                    </a>
                }
                {
                    checkPermission(auth.permissions, 'invoice.show') &&
                    <a

                        className="dropdown-item"
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            openModal('auditModal', {
                                auditableId: value.id,
                                auditableType: 'App\\Models\\Finance',
                                model: 'finance'
                            });
                            setNovo(false)
                        }}
                    >
                        Auditoria
                    </a>
                }
                {
                    value.finance_document?.length > 0 && checkPermission(auth.permissions, permission + '.audit') &&
                    <a

                        className="dropdown-item"
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            openModal('invoiceEventModal', {
                                data:value.finance_document
                            });
                            setNovo(false)
                        }}
                    >
                        Eventos da nota
                    </a>
                }
                {
                    invoice?.status === 4 && checkPermission(auth.permissions, 'invoice.destroy') &&
                    <a

                        className="dropdown-item"
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            openModal('invoiceCancelModal', {
                                id:value.id
                            });
                            setNovo(false)
                        }}
                    >
                        Cancelar nota
                    </a>
                }
                {
                    (invoice?.status === 8 || invoice?.status === 6) && checkPermission(auth.permissions, 'invoice.store') &&
                    <a
                        className="dropdown-item"
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                            openModal('invoiceStoreModal', value);
                        }}
                    >
                        Criar nota
                    </a>
                }
            </div>
        </>
    )
}